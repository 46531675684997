<template>
    <!-- ================================
START HERO-WRAPPER AREA
================================= -->
    <section class="hero-wrapper">
        <div class="hero-box">
            <div id="fullscreen-slide-contain">
                <ul class="slides-container">
                    <li><img src="@/assets/img/1.jpg" alt=""></li>
                    <li><img src="@/assets/img/3.jpg" alt=""></li>
                    <li><img src="@/assets/img/2.jpg" alt=""></li>
                    <li><img src="@/assets/img/4.jpg" alt=""></li>
                </ul>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 mx-auto responsive--column-l">
                        <div class="hero-content pb-5">
                            <div class="section-heading">
                                <h2 class="sec__title cd-headline zoom">
                                    Identity-Based Travel Reviews
                                </h2>
                            </div>
                        </div><!-- end hero-content -->
                        <div class="section-tab text-center pl-4">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link d-flex align-items-center active" id="review-tab" data-toggle="tab" href="#reviews" role="tab" aria-controls="review" aria-selected="true">
                                        <i class="la la-globe mr-1"></i>Reviews
                                    </a>
                                </li>
                            </ul>
                        </div><!-- end section-tab -->
                        <div class="tab-content search-fields-container mr-5" id="myTabContent" style="padding-right: 0">
                            <div class="tab-pane fade show active" id="review" role="tabpanel" aria-labelledby="review-tab">
                                <div class="contact-form-action">
                                    <form action="#" class="row align-items-center">
                                        <div class="col-lg-10 pr-0 ml-4">
                                            <div class="input-box">
                                                <label class="label-text">Find reviews for your next destination</label>
                                                <SearchBar></SearchBar>
                                            </div>
                                        </div><!-- end col-lg-4 -->
                                    </form>
                                </div>
                                <div class="advanced-wrap ml-4 mt-3">
                                    <a class="btn collapse-btn theme-btn-hover-gray font-size-15 ml-n2" data-toggle="collapse" href="#collapseSix" role="button" aria-expanded="false" aria-controls="collapseSix">
                                        Advanced search <i class="la la-angle-down ml-1"></i>
                                    </a>
                                    <div class="pt-3 collapse" id="collapseSix">
                                        <div class="row">
                                            <div class="col-lg-3 pr-0">
                                                <div class="input-box">
                                                    <label class="label-text">Race</label>
                                                    <div class="form-group">
                                                        <div class="select-contain w-auto">
                                                            <select class="select-contain-select" v-model="searchRace">
                                                                <option value="all">All Races</option>
                                                                <option v-for="race in races" :key="race.id" v-bind:value="race.id">{{ race.name }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- end col-lg-3 -->
                                            <div class="col-lg-3 pr-0">
                                                <div class="input-box">
                                                    <label class="label-text">Gender</label>
                                                    <div class="form-group">
                                                        <div class="select-contain w-auto">
                                                            <select class="select-contain-select" v-model="searchGender">
                                                                <option value="all">All Genders</option>
                                                                <option v-for="gender in genders" :key="gender.id" v-bind:value="gender.id">{{ gender.name }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- end col-lg-3 -->
                                            <div class="col-lg-3">
                                                <div class="input-box">
                                                    <label class="label-text">Religion</label>
                                                    <div class="form-group">
                                                        <div class="select-contain w-auto">
                                                            <select class="select-contain-select" v-model="searchReligion">
                                                                <option value="all">All Religions</option>
                                                                <option v-for="religion in religions" :key="religion.id" v-bind:value="religion.id">{{ religion.name }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- end col-lg-3 -->
                                        </div><!-- end row -->
                                        <div class="row">
                                            <div class="col-lg-3">
                                                <div class="input-box">
                                                    <label class="label-text">Sexuality</label>
                                                    <div class="form-group">
                                                        <div class="select-contain w-auto">
                                                            <select class="select-contain-select" v-model="searchOrientation">
                                                                <option value="all">All Sexualities</option>
                                                                <option v-for="orientation in orientations" :key="orientation.id" v-bind:value="orientation.id">{{ orientation.name }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- end col-lg-3 -->
                                            <div class="col-lg-3">
                                                <div class="input-box price-slider-amount">
                                                    <label class="label-text">Age Range</label>
                                                    <AgePicker v-model="searchAge" class="mb-2 ml-2"></AgePicker>
                                                    <p type="text" class="amounts" style="width: 90px; background-color: rgba(40, 125, 250, 0.04);">{{ displayAge }}</p>
                                                </div>
                                            </div><!-- end col-lg-3 -->
                                        </div><!-- end row -->
                                    </div>
                                </div><!-- end advanced-wrap -->

                                <div class="btn-box pt-3 ml-4">
                                    <a href="/country" class="theme-btn">Search Reviews</a>
                                </div>
                            </div><!-- end tab-pane -->
                        </div>
                    </div><!-- end col-lg-12 -->
                </div><!-- end row -->
            </div><!-- end container -->
        !--></div>
    </section><!-- end hero-wrapper -->


    <!-- ================================
    START INFO AREA
================================= -->
    <section class="info-area info-area2 info-bg padding-top-50px padding-bottom-50px text-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="icon-box">
                        <div class="info-icon">
                            <i class="la la-globe"></i>
                        </div><!-- end info-icon-->
                        <div class="info-content">
                            <h4 class="info__title">Community for travelers, by travelers</h4>
                            <p class="info__desc">
                                No longer rely on old forums, blogs, or paid influencers for your travel advice. Read direct, honest feedback and reviews from a diverse community of travelers.
                            </p>
                        </div><!-- end info-content -->
                    </div><!-- end icon-box -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4">
                    <div class="icon-box margin-top-50px">
                        <div class="info-icon">
                            <i class="la la-passport"></i>
                        </div><!-- end info-icon-->
                        <div class="info-content">
                            <h4 class="info__title">Honest and Trustworthy Reviews</h4>
                            <p class="info__desc">
                                With our vetting process and search technology, learn about destinations directly from your peers. Discover the best places to travel from people who are just like you.
                            </p>
                        </div><!-- end info-content -->
                    </div><!-- end icon-box -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4">
                    <div class="icon-box">
                        <div class="info-icon">
                            <i class="lab la-searchengin" style="margin-left: 4px; margin-top: 0.7em; transform: scale(1.1)"></i>
                        </div><!-- end info-icon-->
                        <div class="info-content">
                            <h4 class="info__title">Identity-Based Search Engine</h4>
                            <p class="info__desc">
                                You can fine-grain your search results by filtering reviews by race, age, religion, or gender. With reviews that are recent and relatable, you'll be empowered to finally book that trip.
                            </p>
                        </div><!-- end info-content -->
                    </div><!-- end icon-box -->
                </div><!-- end col-lg-4 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end info-area -->


    <!-- ================================
    START DESTINATION AREA
================================= -->
    <section class="destination-area section--padding" style="padding-bottom: 50px;">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="section-heading">
                        <h2 class="sec__title">Most Popular Destinations</h2>
                        <p class="sec__desc pt-3">The highest-rated travel destinations, according to our reviewers!</p>
                    </div><!-- end section-heading -->
                </div><!-- end col-lg-8 -->
                <div class="col-lg-4">
                    <div class="btn-box btn--box text-right">
                        <a href="/destinations" class="theme-btn">Discover More</a>
                    </div>
                </div>
            </div><!-- end row -->
            <div class="row padding-top-50px">
                <div class="col-lg-4">
                    <div class="card-item destination-card">
                        <div class="card-img">
                            <img src="@/assets/trizen/destination-img2.jpg" alt="destination-img">
                            <span class="badge">North America</span>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">United States</a></h3>
                            <div class="card-rating d-flex align-items-center">
                            <span class="ratings d-flex align-items-center mr-1">
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star-o"></i>
                                <i class="la la-star-o"></i>
                            </span>
                                <span class="rating__text">(70694 Reviews)</span>
                            </div>
                            <div class="card-price d-flex align-items-center justify-content-end" style="margin-bottom: -1rem; margin-top: 0.5rem;">
                                <a href="/country" class="theme-btn theme-btn-small">SEE REVIEWS</a>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                    <div class="card-item destination-card">
                        <div class="card-img">
                            <img src="@/assets/trizen/destination-img3.jpg" alt="destination-img">
                            <span class="badge">Europe</span>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">United Kingdom</a></h3>
                            <div class="card-rating d-flex align-items-center">
                            <span class="ratings d-flex align-items-center mr-1">
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star-o"></i>
                            </span>
                                <span class="rating__text">(70694 Reviews)</span>
                            </div>
                            <div class="card-price d-flex align-items-center justify-content-end" style="margin-bottom: -1rem; margin-top: 0.5rem;">
                                <a href="/country" class="theme-btn theme-btn-small">SEE REVIEWS</a>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4">
                    <div class="card-item destination-card">
                        <div class="card-img">
                            <img src="@/assets/trizen/destination-img4.jpg" alt="destination-img">
                            <span class="badge">Asia</span>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">Hong Kong</a></h3>
                            <div class="card-rating d-flex align-items-center">
                            <span class="ratings d-flex align-items-center mr-1">
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star-o"></i>
                                <i class="la la-star-o"></i>
                            </span>
                                <span class="rating__text">(70694 Reviews)</span>
                            </div>
                            <div class="card-price d-flex align-items-center justify-content-end" style="margin-bottom: -1rem; margin-top: 0.5rem;">
                                <a href="/country" class="theme-btn theme-btn-small">SEE REVIEWS</a>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                    <div class="card-item destination-card">
                        <div class="card-img">
                            <img src="@/assets/trizen/destination-img5.jpg" alt="destination-img">
                            <span class="badge">Africa</span>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">South Africa</a></h3>
                            <div class="card-rating d-flex align-items-center">
                            <span class="ratings d-flex align-items-center mr-1">
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star-o"></i>
                            </span>
                                <span class="rating__text">(70694 Reviews)</span>
                            </div>
                            <div class="card-price d-flex align-items-center justify-content-end" style="margin-bottom: -1rem; margin-top: 0.5rem;">
                                <a href="/country" class="theme-btn theme-btn-small">SEE REVIEWS</a>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4">
                    <div class="card-item destination-card">
                        <div class="card-img">
                            <img src="@/assets/trizen/destination-img.jpg" alt="destination-img">
                            <span class="badge">Asia</span>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">China</a></h3>
                            <div class="card-rating d-flex align-items-center">
                            <span class="ratings d-flex align-items-center mr-1">
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                                <i class="la la-star"></i>
                            </span>
                                <span class="rating__text">(70694 Reviews)</span>
                            </div>
                            <div class="card-price d-flex align-items-center justify-content-end" style="margin-bottom: -1rem; margin-top: 0.5rem;">
                                <a href="/country" class="theme-btn theme-btn-small">SEE REVIEWS</a>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end destination-area -->


    <div class="section-block"></div>

    <!-- ================================
       START TESTIMONIAL AREA
================================= -->
    <section class="testimonial-area section-padding" style="padding-top: 80px;">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="section-heading">
                        <h2 class="sec__title line-height-50">Our Community</h2>
                        <p class="sec__desc padding-top-30px">
                            At TrekWisely, we foster a community of openness and transparency so everyone can rate their experiences honestly.
                        </p>
                        <div class="btn-box padding-top-35px">
                            <a href="#" class="theme-btn">Explore All</a>
                        </div>
                    </div><!-- end section-heading -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-8">
                    <div class="testimonial-carousel carousel-action">
                        <div class="testimonial-card">
                            <div class="testi-desc-box">
                                <p class="testi__desc">Australia is the coolest place I've ever been to! Everyone was super nice and friendly, and I really felt at home in the country. Plus, the tourist attractions were amazing.</p>
                            </div>
                            <div class="author-content d-flex align-items-center">
                                <div class="author-img">
                                    <img src="@/assets/trizen/team10.jpg" alt="testimonial image">
                                </div>
                                <div class="author-bio">
                                    <h4 class="author__title">Luke Jacobs</h4>
                                    <span class="author__meta">Australia</span>
                                    <span class="ratings d-flex align-items-center">
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                </span>
                                </div>
                            </div>
                        </div><!-- end testimonial-card -->
                        <div class="testimonial-card">
                            <div class="testi-desc-box">
                                <p class="testi__desc">Canada was an overrall good experience. While there were a few ups and downs along the way, the people were helpful when they were giving me directions! Would recommend.</p>
                            </div>
                            <div class="author-content d-flex align-items-center">
                                <div class="author-img">
                                    <img src="@/assets/trizen/team9.jpg" alt="testimonial image">
                                </div>
                                <div class="author-bio">
                                    <h4 class="author__title">Richard Pam</h4>
                                    <span class="author__meta">Canada</span>
                                    <span class="ratings d-flex align-items-center">
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star-o"></i>
                                </span>
                                </div>
                            </div>
                        </div><!-- end testimonial-card -->
                        <div class="testimonial-card">
                            <div class="testi-desc-box">
                                <p class="testi__desc">Excepteur sint occaecat cupidatat non proident sunt in culpa officia deserunt mollit anim laborum sint occaecat cupidatat non proident. Occaecat cupidatat non proident des.</p>
                            </div>
                            <div class="author-content d-flex align-items-center">
                                <div class="author-img">
                                    <img src="@/assets/trizen/team8.jpg" alt="testimonial image">
                                </div>
                                <div class="author-bio">
                                    <h4 class="author__title">Leroy Bell</h4>
                                    <span class="author__meta">United States</span>
                                    <span class="ratings d-flex align-items-center">
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                </span>
                                </div>
                            </div>
                        </div><!-- end testimonial-card -->
                        <div class="testimonial-card">
                            <div class="testi-desc-box">
                                <p class="testi__desc">Excepteur sint occaecat cupidatat non proident sunt in culpa officia deserunt mollit anim laborum sint occaecat cupidatat non proident. Occaecat cupidatat non proident des.</p>
                            </div>
                            <div class="author-content d-flex align-items-center">
                                <div class="author-img">
                                    <img src="@/assets/trizen/team8.jpg" alt="testimonial image">
                                </div>
                                <div class="author-bio">
                                    <h4 class="author__title">Chulbul Panday</h4>
                                    <span class="author__meta">Italy</span>
                                    <span class="ratings d-flex align-items-center">
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                    <i class="la la-star"></i>
                                </span>
                                </div>
                            </div>
                        </div><!-- end testimonial-card -->
                    </div><!-- end testimonial-carousel -->
                </div><!-- end col-lg-8 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end testimonial-area -->

    <!-- ================================
    START CTA AREA
================================= -->
    <section class="cta-area cta-bg bg-fixed padding-top-140px padding-bottom-140px text-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-heading">
                        <h2 class="sec__title text-white line-height-55 mt-n4">Our Mission</h2>
                        <h6 class="text-white mt-4" style="font-size: 18px; font-weight: 400; line-height: 28px;">Our mission is to showcase people's traveling experiences based on their backgrounds. We help others
                            <br>feel more secure about their travel decisions with destination reviews submitted by their peers. </h6>
                    </div><!-- end section-heading -->
                    <div class="btn-box padding-top-35px mb-n4">
                        <a href="/mission" class="theme-btn border-0">Learn More</a>
                    </div>
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end cta-area -->

    <!-- ================================
       START BLOG AREA
================================= -->
    <section class="blog-area padding-top-70px padding-bottom-90px">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-heading text-center">
                        <h2 class="sec__title line-height-55">Recent Articles</h2>
                    </div><!-- end section-heading -->
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
            <div class="row padding-top-50px">
                <div class="col-lg-4 responsive-column">
                    <div class="card-item blog-card">
                        <div class="card-img">
                            <img src="@/assets/trizen/blog-img.jpg" alt="blog-img">
                            <div class="card-body">
                                <div class="post-categories">
                                    <a href="#" class="badge">Money</a>
                                </div>
                                <h3 class="card-title line-height-26"><a href="/article">How to Save Money When You're Traveling Abroad</a></h3>
                                <p class="card-meta">
                                    <span class="post__date"> 1 January, 2020</span>
                                    <span class="post-dot"></span>
                                    <span class="post__time">5 Min Read</span>
                                </p>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between">
                            <div class="author-content d-flex align-items-center">
                                <div class="author-img">
                                    <img src="@/assets/trizen/small-team1.jpg" alt="testimonial image">
                                </div>
                                <div class="author-bio">
                                    <a href="#" class="author__title">Leroy Bell</a>
                                </div>
                            </div>
                            <div class="post-share">
                                <ul>
                                    <li>
                                        <i class="la la-share icon-element"></i>
                                        <ul class="post-share-dropdown d-flex align-items-center">
                                            <li><a href="#"><i class="lab la-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="lab la-twitter"></i></a></li>
                                            <li><a href="#"><i class="lab la-instagram"></i></a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="card-item blog-card">
                        <div class="card-img">
                            <img src="@/assets/trizen/blog-img2.jpg" alt="blog-img">
                            <div class="card-body">
                                <div class="post-categories">
                                    <a href="#" class="badge">Travel</a>
                                </div>
                                <h3 class="card-title line-height-26"><a href="/article">Our Best Travel Tips: The Ultimate Travel Guide</a></h3>
                                <p class="card-meta">
                                    <span class="post__date"> 1 February, 2020</span>
                                    <span class="post-dot"></span>
                                    <span class="post__time">4 Min Read</span>
                                </p>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between">
                            <div class="author-content d-flex align-items-center">
                                <div class="author-img">
                                    <img src="@/assets/trizen/small-team2.jpg" alt="testimonial image">
                                </div>
                                <div class="author-bio">
                                    <a href="#" class="author__title">Phillip Hunt</a>
                                </div>
                            </div>
                            <div class="post-share">
                                <ul>
                                    <li>
                                        <i class="la la-share icon-element"></i>
                                        <ul class="post-share-dropdown d-flex align-items-center">
                                            <li><a href="#"><i class="lab la-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="lab la-twitter"></i></a></li>
                                            <li><a href="#"><i class="lab la-instagram"></i></a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="card-item blog-card">
                        <div class="card-img">
                            <img src="@/assets/trizen/blog-img3.jpg" alt="blog-img">
                            <div class="card-body">
                                <div class="post-categories">
                                    <a href="#" class="badge">Navigation</a>
                                </div>
                                <h3 class="card-title line-height-26"><a href="/article">How To Decide What Attractions You Want to See</a></h3>
                                <p class="card-meta">
                                    <span class="post__date"> 1 March, 2020</span>
                                    <span class="post-dot"></span>
                                    <span class="post__time">3 Min Read</span>
                                </p>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between">
                            <div class="author-content d-flex align-items-center">
                                <div class="author-img">
                                    <img src="@/assets/trizen/small-team3.jpg" alt="testimonial image">
                                </div>
                                <div class="author-bio">
                                    <a href="#" class="author__title">Luke Jacobs</a>
                                </div>
                            </div>
                            <div class="post-share">
                                <ul>
                                    <li>
                                        <i class="la la-share icon-element"></i>
                                        <ul class="post-share-dropdown d-flex align-items-center">
                                            <li><a href="#"><i class="lab la-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="lab la-twitter"></i></a></li>
                                            <li><a href="#"><i class="lab la-instagram"></i></a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
            </div><!-- end row -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="btn-box text-center pt-4">
                        <a href="/blog" class="theme-btn">View Our Blog</a>
                    </div>
                </div>
            </div>
        </div><!-- end container -->
    </section><!-- end blog-area -->
</template>

<script>
    import $ from "jquery";
    import "../../js/superslides";

    import * as constants from "../constants";
    import AgePicker from "@/components/pickers/AgePicker"
    import SearchBar from "@/components/SearchBar"

    export default {
        name: 'Home',
        components: {
            AgePicker,
            SearchBar
        },

        data(){
            return {
                races: constants.RACES,
                genders: constants.GENDERS,
                religions: constants.RELIGIONS,
                orientations: constants.ORIENTATIONS,

                searchAge: [18, 34],
                searchRace: 'all',
                searchGender: 'all',
                searchReligion: 'all',
                searchOrientation: 'all'
            }
        },

        computed: {
            displayAge(){
                return this.searchAge[0] + " - " + this.searchAge[1]
            }
        },

        mounted(){
            $('#fullscreen-slide-contain').superslides({
                play: 4000,
                pagination: false,
                animation_speed: 500,
                animation: 'fade'
            });
        }
    }
</script>
