<template>
    <section class="error-area section--padding text-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 mx-auto">
                    <div class="error-img">
                        <img src="@/assets/img/404.png" />
                    </div><!-- end error-img-->
                    <div class="section-heading padding-top-35px">
                        <h2 class="sec__title mb-0">Ooops! This Page Does Not Exist</h2>
                        <p class="sec__desc pt-3">We're sorry, but it appears the website address you entered was <br> incorrect, or is temporarily unavailable.</p>
                    </div>
                    <div class="btn-box padding-top-30px">
                        <a href="/" class="theme-btn"><i class="la la-reply mr-1"></i> Return to Home</a>
                    </div>
                </div><!-- end col-lg-7 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end error-area -->
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>

</style>
