<template>
    <!-- ================================
        START FORM AREA
    ================================= -->
    <section class="listing-form section--padding padding-top-60px">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 mx-auto">
                    <div class="listing-header pb-4">
                        <h3 class="title font-size-28 pb-2">Settings</h3>
                    </div>
                    <div class="form-box">
                        <div class="form-title-wrap">
                            <h3 class="title"><i class="la la-gear mr-2 text-gray"></i>Account Info</h3>
                        </div><!-- form-title-wrap -->
                        <div class="form-content contact-form-action">
                            <div class="row">
                                <div class="col-md-5 user-profile-action d-flex flex-column align-items-center pb-4">
                                    <div class="user-pro-img">
                                        <img src="@/assets/trizen/team7.jpg" alt="user-image">
                                    </div>

                                    <div class="file-upload-wrap file-upload-wrap-2 ml-n4 mt-4">
                                        <input type="file" name="files[]" class="multi file-upload-input with-preview" maxlength="1">
                                        <span class="file-upload-text"><i class="la la-upload mr-2"></i>Change Image</span>
                                    </div>
                                </div>

                                <div class="col-md-5 responsive-column">
                                    <div class="input-box">
                                        <label class="label-text">Your Name</label>
                                        <div class="form-group">
                                            <span class="la la-user form-icon"></span>
                                            <input class="form-control" type="text" name="text" placeholder="Your name">
                                        </div>
                                    </div>

                                    <div class="input-box">
                                        <label class="label-text">Account Email</label>
                                        <div class="form-group">
                                            <span class="la la-envelope-o form-icon"></span>
                                            <input class="form-control" type="email" name="email" placeholder="Your email">
                                        </div>

                                        <div class="input-box">
                                            <label class="label-text">Home Country</label>
                                            <SearchBar></SearchBar>
                                        </div>
                                    </div>
                                </div><!-- end col-lg-6 -->
                            </div>
                        </div><!-- end form-content -->
                    </div><!-- end form-box -->
                    <div class="form-box">
                        <div class="form-title-wrap">
                            <h3 class="title"><i class="la la-user mr-2 text-gray"></i>Travel Profile</h3>
                        </div><!-- form-title-wrap -->
                        <div class="form-content contact-form-action">
                            <form method="post" class="row">
                                <div class="col-lg-6 responsive-column">
                                    <div class="input-box">
                                        <label class="label-text">Race</label>
                                        <div class="form-group">
                                            <div class="select-contain w-auto">
                                                <select class="select-contain-select" v-model="race">
                                                    <option value="all">All Races</option>
                                                    <option v-for="race in races" :key="race.id" v-bind:value="race.id">{{ race.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- end col-lg-6 -->
                                <div class="col-lg-6 responsive-column">
                                    <div class="input-box">
                                        <label class="label-text">Gender</label>
                                        <div class="form-group">
                                            <div class="select-contain w-auto">
                                                <select class="select-contain-select" v-model="gender">
                                                    <option value="all">All Genders</option>
                                                    <option v-for="gender in genders" :key="gender.id" v-bind:value="gender.id">{{ gender.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- end col-lg-6 -->
                                <div class="col-lg-6 responsive-column">
                                    <div class="input-box">
                                        <label class="label-text">Religion</label>
                                        <div class="form-group">
                                            <div class="select-contain w-auto">
                                                <select class="select-contain-select" v-model="religion">
                                                    <option value="all">All Religions</option>
                                                    <option v-for="religion in religions" :key="religion.id" v-bind:value="religion.id">{{ religion.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- end col-lg-6 -->
                                <div class="col-lg-6 responsive-column">
                                    <div class="input-box">
                                        <label class="label-text">Sexuality</label>
                                        <div class="form-group">
                                            <div class="select-contain w-auto">
                                                <select class="select-contain-select" v-model="orientation">
                                                    <option value="all">All Sexualities</option>
                                                    <option v-for="orientation in orientations" :key="orientation.id" v-bind:value="orientation.id">{{ orientation.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- end col-lg-6 -->
                                <div class="col-lg-6 responsive-column">
                                    <div class="input-box price-slider-amount">
                                        <label class="label-text">Age</label>
                                        <AgePicker v-model="age" class="col-10 mb-2 ml-2"></AgePicker>
                                        <p type="text" class="amounts" style="width: 50px; background-color: rgba(40, 125, 250, 0.04);">{{ age }}</p>
                                    </div>
                                </div><!-- end col-lg-6 -->
                            </form>
                        </div><!-- end form-content -->
                    </div><!-- end form-box -->
                    <div class="form-box">
                        <div class="form-title-wrap">
                            <h3 class="title"><i class="la la-lock mr-2 text-gray"></i>Change Password</h3>
                        </div>
                        <div class="form-content">
                            <div class="contact-form-action">
                                <form action="#">
                                    <div class="row">
                                        <div class="col-lg-6 responsive-column">
                                            <div class="input-box">
                                                <label class="label-text">New Password</label>
                                                <div class="form-group">
                                                    <span class="la la-lock form-icon"></span>
                                                    <input class="form-control" type="text" placeholder="New password">
                                                </div>
                                            </div>
                                        </div><!-- end col-lg-6 -->
                                        <div class="col-lg-6 responsive-column">
                                            <div class="input-box">
                                                <label class="label-text">Confirm Password</label>
                                                <div class="form-group">
                                                    <span class="la la-lock form-icon"></span>
                                                    <input class="form-control" type="text" placeholder="New password again">
                                                </div>
                                            </div>
                                        </div><!-- end col-lg-6 -->
                                        <div class="col-lg-12">
                                            <div class="btn-box">
                                                <button class="theme-btn" type="button">Change Password</button>
                                            </div>
                                        </div><!-- end col-lg-12 -->
                                    </div><!-- end row -->
                                </form>
                            </div>
                        </div>
                    </div><!-- end form-box -->
                    <div class="form-box">
                        <div class="form-title-wrap">
                            <h3 class="title"><i class="la la-user mr-2 text-gray"></i>Contact Details</h3>
                        </div><!-- form-title-wrap -->
                        <div class="form-content contact-form-action">
                            <form method="post" class="row">
                                <div class="col-lg-6">
                                    <div class="input-box">
                                        <label class="label-text">Facebook Page</label>
                                        <div class="form-group">
                                            <span class="la la-facebook form-icon"></span>
                                            <input class="form-control" type="text" placeholder="https://www.facebook.com">
                                        </div>
                                    </div>
                                </div><!-- end col-lg-6 -->
                                <div class="col-lg-6">
                                    <div class="input-box">
                                        <label class="label-text">Instagram Page</label>
                                        <div class="form-group">
                                            <span class="la la-instagram form-icon"></span>
                                            <input class="form-control" type="text" placeholder="https://www.instagram.com">
                                        </div>
                                    </div>
                                </div><!-- end col-lg-6 -->
                                <div class="col-lg-6">
                                    <div class="input-box">
                                        <label class="label-text">Twitter Page</label>
                                        <div class="form-group">
                                            <span class="la la-twitter form-icon"></span>
                                            <input class="form-control" type="text" placeholder="https://www.twitter.com">
                                        </div>
                                    </div>
                                </div><!-- end col-lg-6 -->
                                <div class="col-lg-6">
                                    <div class="input-box">
                                        <label class="label-text">Linkedin Page</label>
                                        <div class="form-group">
                                            <span class="la la-linkedin form-icon"></span>
                                            <input class="form-control" type="text" placeholder="https://www.linkedin.com">
                                        </div>
                                    </div>
                                </div><!-- end col-lg-6 -->
                            </form>
                        </div><!-- end form-content -->
                    </div><!-- end form-box -->

                    <button type="submit" class="theme-btn">Save <i class="la la-arrow-right ml-1"></i></button>

                </div><!-- end col-lg-9 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end listing-form -->
    <!-- ================================
        END FORM AREA
    ================================= -->
</template>

<script>
    import * as constants from "../constants";
    import AgePicker from "@/components/pickers/AgePicker"
    import SearchBar from "@/components/SearchBar"

    export default {
        name: "Settings",
        components: {
            AgePicker,
            SearchBar
        },

        data(){
            return {
                races: constants.RACES,
                genders: constants.GENDERS,
                religions: constants.RELIGIONS,
                orientations: constants.ORIENTATIONS,

                age: 0,
                race: null,
                gender: null,
                religion: null,
                orientation: null
            }
        }
    }
</script>

<style scoped>

</style>
