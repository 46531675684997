<template>
    <Navbar></Navbar>

    <router-view/>

    <div id="back-to-top">
        <i class="la la-angle-up" title="Go top"></i>
    </div>

    <Footer></Footer>
</template>

<script>
    import Navbar from "@/components/Navbar";
    import Footer from "@/components/Footer";

    export default {
        components: {
            Navbar,
            Footer
        },

        // TODO fix whatever tf this is

        mounted(){
            this.$nextTick(() => {
                import("../js/main");
            })
        },

        methods: {
            runSearch(){
                alert("Done.")
            }
        }
    }
</script>
