import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home'
import NotFound from "./views/404"
import AddNewPost from "./views/blog/AddNewPost"
import Mission from "./views/Mission"
import Blog from "./views/blog/Blog"
import BlogArticle from "./views/blog/BlogArticle"
import Country from "./views/Country"
import Destinations from "./views/Destinations"
import Profile from "./views/Profile"
import Settings from "./views/Settings"

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/post',
        name: 'AddNewPost',
        component: AddNewPost
    },
    {
        path: '/mission',
        name: 'Mission',
        component: Mission
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/article',
        name: 'BlogArticle',
        component: BlogArticle
    },
    {
        path: '/country',
        name: 'Country',
        component: Country
    },
    {
        path: '/destinations',
        name: 'Destinations',
        component: Destinations
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
