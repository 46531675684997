<template>

    <!-- ================================
        START CARD AREA
    ================================= -->
    <section class="card-area section--padding pt-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="filter-wrap margin-bottom-30px">
                        <div class="filter-top d-flex align-items-center justify-content-between pb-4">
                            <div>
                                <h3 class="title font-size-24">2292 Destinations Found</h3>
                                <p class="font-size-14 line-height-20 pt-2">Our smart filters automatically show you the best destinations based on your profile.</p>
                            </div>
                        </div><!-- end filter-top -->
                        <div class="filter-bar d-flex align-items-center justify-content-between">
                            <div class="filter-bar-filter d-flex flex-wrap align-items-center">
                                <div class="filter-option">
                                    <h3 class="title font-size-16">Filter by:</h3>
                                </div>

                                <div class="filter-option">
                                    <div class="dropdown dropdown-contain">
                                        <a class="dropdown-toggle dropdown-btn dropdown--btn" href="#" role="button" data-toggle="dropdown">
                                            Continent
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-wrap">
                                            <div class="dropdown-item">
                                                <div class="checkbox-wrap">
                                                    <div class="custom-checkbox" v-for="continent in continents" :key="continent.id">
                                                        <input type="checkbox" :id="continent.id" :value="continent.id" v-model="filterContinents">
                                                        <label :for="continent.id">{{ continent.name }}</label>
                                                    </div>
                                                </div>
                                            </div><!-- end dropdown-item -->
                                        </div><!-- end dropdown-menu -->
                                    </div><!-- end dropdown -->
                                </div>


                                <div class="filter-option">
                                    <div class="dropdown dropdown-contain">
                                        <a class="dropdown-toggle dropdown-btn dropdown--btn" href="#" role="button" data-toggle="dropdown">
                                            Price
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-wrap">
                                            <div class="dropdown-item">
                                                <div class="checkbox-wrap">
                                                    <div class="custom-checkbox">
                                                        <input type="checkbox" id="cost_cheap">
                                                        <label for="cost_cheap">Bargain
                                                            <span class="badge badge-success text-white font-size-13 font-weight-normal" style="margin-left: 34px;">$</span>
                                                        </label>
                                                    </div>
                                                    <div class="custom-checkbox">
                                                        <input type="checkbox" id="cost_medium">
                                                        <label for="cost_medium">Affordable
                                                            <span class="badge badge-success text-white font-size-13 font-weight-normal" style="margin-left: 14px;">$$</span>
                                                        </label>
                                                    </div>
                                                    <div class="custom-checkbox">
                                                        <input type="checkbox" id="cost_expensive">
                                                        <label for="cost_expensive">Expensive
                                                            <span class="badge badge-success text-white font-size-13 font-weight-normal" style="margin-left: 16px;">$$$</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div><!-- end dropdown-item -->
                                        </div><!-- end dropdown-menu -->
                                    </div><!-- end dropdown -->
                                </div>


                                <div class="filter-option">
                                    <div class="dropdown dropdown-contain">
                                        <a class="dropdown-toggle dropdown-btn dropdown--btn" href="#" role="button" data-toggle="dropdown">
                                            Review Score
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-wrap">
                                            <div class="dropdown-item">
                                                <div class="checkbox-wrap">
                                                    <div class="custom-checkbox">
                                                        <input type="checkbox" id="r1">
                                                        <label for="r1">
                                                        <span class="ratings d-flex align-items-center">
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star"></i>
                                                            <span class="color-text-3 font-size-13 ml-1">(55.590)</span>
                                                        </span>
                                                        </label>
                                                    </div>
                                                    <div class="custom-checkbox">
                                                        <input type="checkbox" id="r2">
                                                        <label for="r2">
                                                        <span class="ratings d-flex align-items-center">
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star-o"></i>
                                                            <span class="color-text-3 font-size-13 ml-1">(40.590)</span>
                                                        </span>
                                                        </label>
                                                    </div>
                                                    <div class="custom-checkbox">
                                                        <input type="checkbox" id="r3">
                                                        <label for="r3">
                                                        <span class="ratings d-flex align-items-center">
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star-o"></i>
                                                            <i class="la la-star-o"></i>
                                                            <span class="color-text-3 font-size-13 ml-1">(23.590)</span>
                                                        </span>
                                                        </label>
                                                    </div>
                                                    <div class="custom-checkbox">
                                                        <input type="checkbox" id="r4">
                                                        <label for="r4">
                                                        <span class="ratings d-flex align-items-center">
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star-o"></i>
                                                            <i class="la la-star-o"></i>
                                                            <i class="la la-star-o"></i>
                                                            <span class="color-text-3 font-size-13 ml-1">(12.590)</span>
                                                        </span>
                                                        </label>
                                                    </div>
                                                    <div class="custom-checkbox">
                                                        <input type="checkbox" id="r5">
                                                        <label for="r5">
                                                        <span class="ratings d-flex align-items-center">
                                                            <i class="la la-star"></i>
                                                            <i class="la la-star-o"></i>
                                                            <i class="la la-star-o"></i>
                                                            <i class="la la-star-o"></i>
                                                            <i class="la la-star-o"></i>
                                                            <span class="color-text-3 font-size-13 ml-1">(590)</span>
                                                        </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div><!-- end dropdown-item -->
                                        </div><!-- end dropdown-menu -->
                                    </div><!-- end dropdown -->
                                </div>
                            </div><!-- end filter-bar-filter -->
                            <div class="select-contain">
                                <p class="sort-by">Sort by: </p>
                                <select class="select-contain-select" style="display: inline-block;">
                                    <option value="1">Relevancy</option>
                                    <option value="2">Popularity</option>
                                    <option value="3">Highest rating</option>
                                </select>
                            </div><!-- end select-contain -->
                        </div><!-- end filter-bar -->
                    </div><!-- end filter-wrap -->
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
            <div class="row">
                <div class="col-lg-4 responsive-column">
                    <div class="card-item fade show">
                        <div class="card-img">
                            <div class="card-img-carousel carousel-action carousel--action carousel-show-nav d-block">
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img16.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img22.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img23.jpg" alt="hotel-img">
                                    </a>
                                </div>
                            </div>

                            <div class="add-to-wishlist icon-element" data-toggle="tooltip" data-placement="top" title="Save for Later" style="z-index: 999">
                                <i class="la la-heart-o"></i>
                            </div>
                            <span class="badge">Popular</span>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">Mexico</a></h3>
                            <p class="card-meta">North America</p>

                            <div class="card-rating pt-0 pb-0 mt-n1">
                                <div class="card-rating d-flex align-items-center">
                                    <span class="ratings d-flex align-items-center mr-1">
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star-o"></i>
                                        <i class="la la-star-o"></i>
                                    </span>
                                    <span class="badge text-white mr-2">Average</span>
                                    <span class="rating__text">(70694 Reviews)</span>
                                </div>

                                <p class="card-meta mt-n2 text-sm">Based on your profile.</p>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="card-item">
                        <div class="card-img">
                            <div class="card-img-carousel carousel-action carousel--action carousel-show-nav d-block">
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img19.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img18.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img17.jpg" alt="hotel-img">
                                    </a>
                                </div>
                            </div>
                            <div class="add-to-wishlist icon-element" data-toggle="tooltip" data-placement="top" title="Save for Later" style="z-index: 999">
                                <i class="la la-heart-o"></i>
                            </div>
                            <span class="badge">Popular</span>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">Morocoo</a></h3>
                            <p class="card-meta">Africa</p>

                            <div class="card-rating pt-0 pb-0 mt-n1">
                                <div class="card-rating d-flex align-items-center">
                                    <span class="ratings d-flex align-items-center mr-1">
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star-o"></i>
                                    </span>
                                    <span class="badge text-white mr-2">Good</span>
                                    <span class="rating__text">(70694 Reviews)</span>
                                </div>

                                <p class="card-meta mt-n2 text-sm">Based on your profile.</p>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="card-item">
                        <div class="card-img">
                            <div class="card-img-carousel carousel-action carousel--action carousel-show-nav d-block">
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img10.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img11.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img12.jpg" alt="hotel-img">
                                    </a>
                                </div>
                            </div>
                            <div class="add-to-wishlist icon-element" data-toggle="tooltip" data-placement="top" title="Save for Later" style="z-index: 999">
                                <i class="la la-heart-o"></i>
                            </div>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">United States</a></h3>
                            <p class="card-meta">North America</p>

                            <div class="card-rating pt-0 pb-0 mt-n1">
                                <div class="card-rating d-flex align-items-center">
                                    <span class="ratings d-flex align-items-center mr-1">
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star-o"></i>
                                    </span>
                                    <span class="badge text-white mr-2">Good</span>
                                    <span class="rating__text">(70694 Reviews)</span>
                                </div>

                                <p class="card-meta mt-n2 text-sm">Based on your profile.</p>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="card-item">
                        <div class="card-img">
                            <div class="card-img-carousel carousel-action carousel--action carousel-show-nav d-block">
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img13.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img14.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img15.jpg" alt="hotel-img">
                                    </a>
                                </div>
                            </div>
                            <div class="add-to-wishlist icon-element" data-toggle="tooltip" data-placement="top" title="Save for Later" style="z-index: 999">
                                <i class="la la-heart-o"></i>
                            </div>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">New Zealand</a></h3>
                            <p class="card-meta">Asia</p>

                            <div class="card-rating pt-0 pb-0 mt-n1">
                                <div class="card-rating d-flex align-items-center">
                                    <span class="ratings d-flex align-items-center mr-1">
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                    </span>
                                    <span class="badge text-white mr-2">Excellent</span>
                                    <span class="rating__text">(70694 Reviews)</span>
                                </div>

                                <p class="card-meta mt-n2 text-sm">Based on your profile.</p>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="card-item">
                        <div class="card-img">
                            <div class="card-img-carousel carousel-action carousel--action carousel-show-nav d-block">
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img15.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img16.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img17.jpg" alt="hotel-img">
                                    </a>
                                </div>
                            </div>
                            <div class="add-to-wishlist icon-element" data-toggle="tooltip" data-placement="top" title="Save for Later" style="z-index: 999">
                                <i class="la la-heart-o"></i>
                            </div>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">France</a></h3>
                            <p class="card-meta">Europe</p>

                            <div class="card-rating pt-0 pb-0 mt-n1">
                                <div class="card-rating d-flex align-items-center">
                                    <span class="ratings d-flex align-items-center mr-1">
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star"></i>
                                        <i class="la la-star-o"></i>
                                    </span>
                                    <span class="badge text-white mr-2">Good</span>
                                    <span class="rating__text">(70694 Reviews)</span>
                                </div>

                                <p class="card-meta mt-n2 text-sm">Based on your profile.</p>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="card-item">
                        <div class="card-img">
                            <div class="card-img-carousel carousel-action carousel--action carousel-show-nav d-block">
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img17.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img8.jpg" alt="hotel-img">
                                    </a>
                                </div>
                                <div class="card-img">
                                    <a href="/country" class="d-block">
                                        <img src="@/assets/trizen/img19.jpg" alt="hotel-img">
                                    </a>
                                </div>
                            </div>
                            <div class="add-to-wishlist icon-element" data-toggle="tooltip" data-placement="top" title="Save for Later" style="z-index: 999">
                                <i class="la la-heart-o"></i>
                            </div>
                        </div>
                        <div class="card-body">
                            <h3 class="card-title"><a href="/country">Australia</a></h3>
                            <p class="card-meta">Australia</p>

                            <div class="card-rating pt-0 pb-0 mt-n1">
                                <div class="card-rating d-flex align-items-center">
                                    <span class="ratings d-flex align-items-center mr-1">
                                        <i class="la la-star"></i>
                                        <i class="la la-star-0"></i>
                                        <i class="la la-star-o"></i>
                                        <i class="la la-star-o"></i>
                                        <i class="la la-star-o"></i>
                                    </span>
                                    <span class="badge text-white mr-2">Below Average</span>
                                    <span class="rating__text">(70694 Reviews)</span>
                                </div>

                                <p class="card-meta mt-n2 text-sm">Based on your profile.</p>
                            </div>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
            </div><!-- end row -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="btn-box mt-3 text-center">
                        <ul class="pagination justify-content-center pb-1 pt-3">
                            <li class="page-item">
                                <a class="page-link page-link-nav" href="#" aria-label="Previous">
                                    <span aria-hidden="true"><i class="la la-angle-left"></i></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link page-link-nav" href="#">1 <span class="sr-only">(current)</span></a>
                            </li>
                            <li class="page-item"><a class="page-link page-link-nav" href="#">2</a></li>
                            <li class="page-item"><a class="page-link page-link-nav" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link page-link-nav" href="#" aria-label="Next">
                                    <span aria-hidden="true"><i class="la la-angle-right"></i></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                        <p class="font-size-13 pt-2">Showing 1 - 6 of 2292 Destinations</p>
                    </div><!-- end btn-box -->
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end card-area -->
    <!-- ================================
        END CARD AREA
    ================================= -->


</template>

<script>
    import * as constants from "../constants"

    export default {
        name: "Destinations",

        data() {
            return {
                continents: constants.CONTINENTS,
                filterContinents: []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sort-by {
        margin-left: -5em;
        margin-right: 1em;
        display: inline-block;
    }

    .select-contain {
        @media (max-width: 767px){
            margin-left: 5em;
        }

        @media (min-width: 768px) and (max-width: 991px){
            margin-right: -1em;
        }
    }
</style>
