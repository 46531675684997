<template>
    <v-select class="v-selector"
              label="name"
              placeholder="Destination, country, or island"
              v-model="selected"
              :options="paginated"
              :filterable="false"
              @open="onOpen"
              @close="onClose"
              @search="query => search = query"
    >
        <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
                No countries found named <em>{{ search }}</em>.
            </template>
            <em style="opacity: 0.5;" v-else>Start typing to search for a country.</em>
        </template>

        <template v-slot:option="country">
            <span class="flag-icon" :class="'flag-icon-' + country.code"></span>
            {{ country.name }}
        </template>

        <template #search="{ attributes, events }">
            <span class="la la-map-marker form-icon" :class="{'d-none': selected}"></span>
            <input class="vs__search" v-bind="attributes" v-on="events" :required="!selected">
        </template>

        <template #selected-option="{ name, code }">
            <div style="display: flex; align-items: baseline;">
                <span class="flag-icon" :class="'flag-icon-' + code"></span>
                {{ name }}
            </div>
        </template>

        <template #open-indicator="{ attributes }">
            <span class="la la-angle-down" v-bind="attributes"></span>
        </template>

        <template #list-footer>
            <li ref="load" style="opacity: 0" v-show="hasNextPage">
                Loading more options...
            </li>
        </template>
    </v-select>
</template>

<script>
    import $ from "jquery"
    import vSelect from "vue-select";

    export default {
        name: "SearchBar",
        components: {
            vSelect
        },

        data () {
            return {
                selected: false,
                observer: null,
                limit: 5,
                search: '',

                countries: [
                    {
                        name: 'United States',
                        code: 'us',
                        url: ''
                    },
                    {
                        name: 'United Kingdom',
                        code: 'gb',
                        url: ''
                    },
                    {
                        name: 'United Arab Emirates',
                        code: 'ae',
                        url: ''
                    },
                    {
                        name: 'France',
                        code: 'fr',
                        url: ''
                    },
                    {
                        name: 'China',
                        code: 'cn',
                        url: ''
                    },
                    {
                        name: 'Russia',
                        code: 'ru',
                        url: ''
                    },
                    {
                        name: 'Nigeria',
                        code: 'ng',
                        url: ''
                    }
                ]
            }
        },

        mounted(){
            this.observer = new IntersectionObserver(this.infiniteScroll);
        },

        computed: {
            filtered() {
                return this.countries.filter(country => country.name.toLowerCase().includes(this.search.toLowerCase()));
            },
            paginated() {
                return this.filtered.slice(0, this.limit);
            },
            hasNextPage() {
                return this.paginated.length < this.filtered.length;
            },
        },

        methods: {
            async onOpen() {
                if (this.hasNextPage) {
                    await this.$nextTick();
                    this.observer.observe(this.$refs.load)
                }
            },

            onClose() {
                this.observer.disconnect();
            },

            async infiniteScroll ([{isIntersecting, target}]) {
                if (isIntersecting) {
                    const ul = target.offsetParent;
                    const scrollTop = target.offsetParent.scrollTop;
                    this.limit += 10;
                    await this.$nextTick();
                    ul.scrollTop = scrollTop;
                }
            }
        },

        watch: {
            selected: function (val){
                if (val) $('.vs__dropdown-toggle').addClass('highlighted-border');
                else $('.vs__dropdown-toggle').removeClass('highlighted-border')
            }
        }
    }
</script>

<style lang="scss">
    @import "../../sass/partials/variables";
    @import "../../sass/partials/mixins";

    .v-selector .vs__search::placeholder,
    .v-selector .vs__dropdown-toggle,
    .v-selector .vs__dropdown-menu {
        border: none;
        font-size: 13px;
    }

    .v-selector .vs__dropdown-menu li {
        font-size: 14px;
        padding-top: 0.75em;
        padding-bottom: 0.75em;
    }

    .v-selector .vs__search::placeholder {
        color: #696f78
    }

    .v-selector .vs__search {
        margin-left: -0.4em !important;
    }

    .v-selector .vs__dropdown-toggle,
    .v-selector .vs__dropdown-menu {
        border: 1px solid #ced4da;
        transition: 0.3s;
    }

    .v-selector .vs__dropdown-toggle {
        padding: 12px 20px 12px 40px;
        font-size: $default-font-4;
        color: $theme-color;
        border-color: rgba(128, 137, 150, 0.2);
        @include box-shadow(none);
        @include transition(0.3s);
        background-color: $white;
    }

    .v-selector .vs__selected {
        padding: 0;
        margin-left: -1.6em;
        font-size: 14px;
    }

    .v-selector .vs__clear:before {
        font-family: 'Line Awesome Free';
        font-weight: 900;
        font-size: 15px;
        position: relative;
        top: 1px;
        right: 4px;
        content: "\f00d";
    }

    .v-selector .vs__clear svg {
        display: none !important;
    }

    .v-selector .vs__dropdown-toggle[aria-expanded="true"] {
        border: 1px solid #287dfa;
    }

    .highlighted-border {
        border: 1px solid #287dfa !important;
    }

    .form-icon {
        position: absolute;
        top: 4px;
        left: -1.25em;
        color: $theme-color-4;
        font-size: 18px;
    }

    .flag-icon {
        border-radius: 2px;
        font-size: 15px;
        margin-right: 12px;
    }
</style>
