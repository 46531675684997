<template>
    <!-- ================================
        START USER AREA
    ================================= -->
    <section class="user-area padding-top-40px padding-bottom-60px">
        <div class="container">
            <div class="row padding-top-40px">
                <div class="col-lg-9 mt-n3">
                    <div class="card-item user-card card-item-list mt-4 mb-0">
                        <div class="card-img user-img">
                            <img src="@/assets/trizen/team7.jpg" alt="user image" class="h-auto">
                        </div>
                        <div class="card-body">
                            <h3 class="card-title font-size-24">Samuel Jackson</h3>
                            <p class="card-meta mt-n1">United States</p>
                            <p class="card-meta mt-1">Member since April 2020</p>
                            <div class="d-flex justify-content-between pt-3">
                                <ul class="list-items flex-grow-1">
                                    <li><b>Race:</b> African American</li>
                                    <li><b>Gender:</b> Male</li>
                                    <li><b>Age: </b> <span class="theme-btn theme-btn-small theme-btn-rgb">Hidden</span></li>
                                    <li><b>Religion: </b> Christianity</li>
                                    <li><b>Sexuality:</b> <span class="theme-btn theme-btn-small theme-btn-rgb">Hidden</span></li>
                                </ul>
                            </div>
                        </div>
                    </div><!-- end card-item -->

                    <div class="section-tab section-tab-3 pt-5 pb-4">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="my-activity-tab" data-toggle="tab" href="#my-activity" role="tab" aria-controls="my-activity" aria-selected="true">
                                    Countries
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="my-review-tab" data-toggle="tab" href="#my-review" role="tab" aria-controls="my-review" aria-selected="false">
                                    Reviews
                                </a>
                            </li>
                        </ul>
                    </div><!-- end section-tab -->
                    <div class="tab-content margin-bottom-40px" id="myTabcontent">
                        <div class="tab-pane fade show active" id="my-activity" role="tabpanel" aria-labelledby="my-activity-tab">
                            <div class="my-service-list">
                                <div class="card-item card-item-list card-item--list">
                                    <div class="card-img">
                                        <a href="tour-details.html" class="d-block">
                                            <img src="@/assets/trizen/img13.jpg" alt="Destination-img">
                                        </a>
                                        <span class="badge">Featured</span>
                                    </div>
                                    <div class="card-body">
                                        <h3 class="card-title"><a href="tour-details.html">New Zealand</a></h3>
                                        <p class="card-meta">Asia</p>
                                        <div class="card-rating d-flex align-items-center pt-0">
                                        <span class="ratings d-flex align-items-center mr-2">
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                        </span>
                                            <span class="badge text-white mr-2">Excellent</span>
                                            <span class="rating__text">(324 Reviews)</span>
                                        </div>
                                        <p class="card-meta">New Zealand is a wealthy Pacific nation dominated by two cultural groups: European and Polynesian.
                                            New Zealand has diversified its export markets and has developed strong trade links with Australia, the US, and Japan.</p>
                                        <div class="card-price d-flex align-items-center mt-3 mb-n2">
                                            <a href="tour-details.html" class="theme-btn theme-btn-small">See Reviews</a>
                                        </div>
                                    </div>
                                </div><!-- end card-item -->
                                <div class="card-item card-item-list card-item--list">
                                    <div class="card-img">
                                        <a href="tour-details.html" class="d-block">
                                            <img src="@/assets/trizen/img14.jpg" alt="Destination-img">
                                        </a>
                                    </div>
                                    <div class="card-body">
                                        <h3 class="card-title"><a href="tour-details.html">Ireland</a></h3>
                                        <p class="card-meta">Europe</p>
                                        <div class="card-rating d-flex align-items-center pt-0">
                                        <span class="ratings d-flex align-items-center mr-2">
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star-o"></i>
                                        </span>
                                            <span class="badge text-white mr-2">Good</span>
                                            <span class="rating__text">(3078 Reviews)</span>
                                        </div>
                                        <p class="card-meta">Ireland is an island nation on the westernmost edge of Europe. The Republic of Ireland occupies 80 percent of this landmass, and in fact, its nickname is the Emerald Isle.</p>
                                        <div class="card-price d-flex align-items-center mt-3 mb-n2">
                                            <a href="tour-details.html" class="theme-btn theme-btn-small">See Reviews</a>
                                        </div>
                                    </div>
                                </div><!-- end card-item -->
                                <div class="card-item card-item-list card-item--list">
                                    <div class="card-img">
                                        <a href="tour-details.html" class="d-block">
                                            <img src="@/assets/trizen/img15.jpg" alt="Destination-img">
                                        </a>
                                        <span class="badge">Featured</span>
                                    </div>
                                    <div class="card-body">
                                        <h3 class="card-title"><a href="tour-details.html">France</a></h3>
                                        <p class="card-meta">Europe</p>
                                        <div class="card-rating d-flex align-items-center pt-0">
                                        <span class="ratings d-flex align-items-center mr-2">
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star-o"></i>
                                            <i class="la la-star-o"></i>
                                        </span>
                                            <span class="badge text-white mr-2">Average</span>
                                            <span class="rating__text">(276 Reviews)</span>
                                        </div>
                                        <p class="card-meta">France remains one the planet's most visited tourist destination, meriting its standing with an almost overwhelming mass of historical treasures,
                                            storybook landscapes, and cultural idiosyncrasies.</p>
                                        <div class="card-price d-flex align-items-center mt-3 mb-n2">
                                            <a href="tour-details.html" class="theme-btn theme-btn-small">See Reviews</a>
                                        </div>
                                    </div>
                                </div><!-- end card-item -->
                                <div class="card-item card-item-list card-item--list">
                                    <div class="card-img">
                                        <a href="tour-details.html" class="d-block">
                                            <img src="@/assets/trizen/img16.jpg" alt="Destination-img">
                                        </a>
                                        <span class="badge">Popular</span>
                                    </div>
                                    <div class="card-body">
                                        <h3 class="card-title"><a href="tour-details.html">Mexico</a></h3>
                                        <p class="card-meta">North America</p>
                                        <div class="card-rating d-flex align-items-center pt-0">
                                        <span class="ratings d-flex align-items-center mr-2">
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star-o"></i>
                                        </span>
                                            <span class="badge text-white mr-2">Great</span>
                                            <span class="rating__text">(8423 Reviews)</span>
                                        </div>
                                        <p class="card-meta">With a population of almost 130 million, a rich cultural history and diversity, and abundant natural resources,
                                            Mexico is among the world's 15 largest economies in the world and the second largest economy in Latin America. </p>
                                        <div class="card-price d-flex align-items-center mt-3 mb-n2">
                                            <a href="tour-details.html" class="theme-btn theme-btn-small">See Reviews</a>
                                        </div>
                                    </div>
                                </div><!-- end card-item -->
                            </div><!-- end my-service-list -->
                            <nav aria-label="Page navigation example" class="pt-4">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a class="page-link page-link-nav" href="#" aria-label="Previous">
                                            <span aria-hidden="true"><i class="la la-angle-left"></i></span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li class="page-item active">
                                        <a class="page-link page-link-nav" href="#">1 <span class="sr-only">(current)</span></a>
                                    </li>
                                    <li class="page-item"><a class="page-link page-link-nav" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link page-link-nav" href="#">3</a></li>
                                    <li class="page-item">
                                        <a class="page-link page-link-nav" href="#" aria-label="Next">
                                            <span aria-hidden="true"><i class="la la-angle-right"></i></span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div><!-- end tab-pane -->
                        <div class="tab-pane fade" id="my-review" role="tabpanel" aria-labelledby="my-review-tab">
                            <div class="my-service-list">
                                <div class="section-block"></div>
                                <div class="review-box padding-top-45px">
                                    <h3 class="title font-size-20">List of Reviews</h3>
                                    <div class="comments-list padding-top-40px">
                                        <div class="comment">
                                            <div class="comment-avatar">
                                                <a href="#" class="d-block">
                                                    <img class="avatar__img" alt="" src="@/assets/trizen/img1.jpg">
                                                </a>
                                            </div>
                                            <div class="comment-body">
                                                <div class="meta-data">
                                                    <h3 class="comment__meta font-size-18 pb-2">"Excellent"</h3>
                                                    <h3 class="comment__author font-size-15 font-weight-medium">Review for <a href="#" class="color-text">Madagascar</a></h3>
                                                    <div class="meta-data-inner d-flex">
                                                    <span class="ratings d-flex align-items-center mr-1">
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                    </span>
                                                        <p class="comment__date">April 5, 2019</p>
                                                    </div>
                                                </div>
                                                <p class="comment-content font-size-15 line-height-24">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis eveniet exercitationem fuga id laborum nostrum numquam quo repellendus similique.
                                                </p>
                                                <div class="comment-reply">
                                                    <div class="reviews-reaction">
                                                        <a href="#" class="comment-like"><i class="la la-thumbs-up"></i> 13</a>
                                                        <a href="#" class="comment-dislike"><i class="la la-thumbs-down"></i> 2</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment">
                                            <div class="comment-avatar">
                                                <a href="#" class="d-block">
                                                    <img class="avatar__img" alt="" src="@/assets/trizen/img2.jpg">
                                                </a>
                                            </div>
                                            <div class="comment-body">
                                                <div class="meta-data">
                                                    <h3 class="comment__meta font-size-18 pb-2">"Good"</h3>
                                                    <h3 class="comment__author font-size-15 font-weight-medium">Review for <a href="#" class="color-text">Japan</a></h3>
                                                    <div class="meta-data-inner d-flex">
                                                    <span class="ratings d-flex align-items-center mr-1">
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star-o"></i>
                                                        <i class="la la-star-o"></i>
                                                    </span>
                                                        <p class="comment__date">April 5, 2019</p>
                                                    </div>
                                                </div>
                                                <p class="comment-content font-size-15 line-height-24">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis eveniet exercitationem fuga id laborum nostrum numquam quo repellendus similique.
                                                </p>
                                                <div class="comment-reply">
                                                    <div class="reviews-reaction">
                                                        <a href="#" class="comment-like"><i class="la la-thumbs-up"></i> 13</a>
                                                        <a href="#" class="comment-dislike"><i class="la la-thumbs-down"></i> 2</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment">
                                            <div class="comment-avatar">
                                                <a href="#" class="d-block">
                                                    <img class="avatar__img" alt="" src="@/assets/trizen/img3.jpg">
                                                </a>
                                            </div>
                                            <div class="comment-body">
                                                <div class="meta-data">
                                                    <h3 class="comment__meta font-size-18 pb-2">"Great"</h3>
                                                    <h3 class="comment__author font-size-15 font-weight-medium">Review for <a href="#" class="color-text">South Africa</a></h3>
                                                    <div class="meta-data-inner d-flex">
                                                    <span class="ratings d-flex align-items-center mr-1">
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star-o"></i>
                                                    </span>
                                                        <p class="comment__date">April 5, 2019</p>
                                                    </div>
                                                </div>
                                                <p class="comment-content font-size-15 line-height-24">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis eveniet exercitationem fuga id laborum nostrum numquam quo repellendus similique.
                                                </p>
                                                <div class="comment-reply">
                                                    <div class="reviews-reaction">
                                                        <a href="#" class="comment-like"><i class="la la-thumbs-up"></i> 13</a>
                                                        <a href="#" class="comment-dislike"><i class="la la-thumbs-down"></i> 2</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment">
                                            <div class="comment-avatar">
                                                <a href="#" class="d-block">
                                                    <img class="avatar__img" alt="" src="@/assets/trizen/img4.jpg">
                                                </a>
                                            </div>
                                            <div class="comment-body">
                                                <div class="meta-data">
                                                    <h3 class="comment__meta font-size-18 pb-2">"Poor"</h3>
                                                    <h3 class="comment__author font-size-15 font-weight-medium">Review for <a href="#" class="color-text">Russia</a></h3>
                                                    <div class="meta-data-inner d-flex">
                                                    <span class="ratings d-flex align-items-center mr-1">
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star-o"></i>
                                                        <i class="la la-star-o"></i>
                                                        <i class="la la-star-o"></i>
                                                        <i class="la la-star-o"></i>
                                                    </span>
                                                        <p class="comment__date">April 5, 2019</p>
                                                    </div>
                                                </div>
                                                <p class="comment-content font-size-15 line-height-24">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis eveniet exercitationem fuga id laborum nostrum numquam quo repellendus similique.
                                                </p>
                                                <div class="comment-reply">
                                                    <div class="reviews-reaction">
                                                        <a href="#" class="comment-like"><i class="la la-thumbs-up"></i> 13</a>
                                                        <a href="#" class="comment-dislike"><i class="la la-thumbs-down"></i> 2</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end comments-list -->
                                </div>
                                <nav aria-label="Page navigation example" class="pt-4">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link page-link-nav" href="#" aria-label="Previous">
                                                <span aria-hidden="true"><i class="la la-angle-left"></i></span>
                                                <span class="sr-only">Previous</span>
                                            </a>
                                        </li>
                                        <li class="page-item"><a class="page-link page-link-nav" href="#">1</a></li>
                                        <li class="page-item active">
                                            <a class="page-link page-link-nav" href="#">2 <span class="sr-only">(current)</span></a>
                                        </li>
                                        <li class="page-item"><a class="page-link page-link-nav" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link page-link-nav" href="#" aria-label="Next">
                                                <span aria-hidden="true"><i class="la la-angle-right"></i></span>
                                                <span class="sr-only">Next</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div><!-- end tab-pane -->
                    </div>
                </div><!-- end col-lg-9 -->
                <div class="col-lg-3">
                    <div class="review-summary mt-2 section-bg">
                        <p>Average Rating</p>
                        <h2>4.5<span>/5</span></h2>
                        <span class="ratings d-flex justify-content-center align-items-center font-size-17">
                        <i class="la la-star"></i>
                        <i class="la la-star"></i>
                        <i class="la la-star"></i>
                        <i class="la la-star"></i>
                        <i class="la la-star-half-alt"></i>
                    </span>
                        <span class="font-size-14">(Based on 199 reviews)</span>
                    </div><!-- end review-summary -->
                    <div class="form-box margin-top-30px mb-0">
                        <div class="form-title-wrap">
                            <h3 class="title">Contact</h3>
                        </div>
                        <div class="form-content">
                            <ul class="social-profile-2">
                                <li class="mb-3"><a class="mr-2" href="#"><i class="social-icon lab la-facebook-f"></i>Facebook</a></li>
                                <li class="mb-3"><a class="mr-2" href="#"><i class="social-icon lab la-twitter"></i>Twitter</a></li>
                                <li class="mb-3"><a class=" mr-2" href="#"><i class="social-icon lab la-instagram"></i>Instagram</a></li>
                                <li class="mb-0"><a class=" mr-2" href="#"><i class="social-icon lab la-linkedin-in"></i>LinkedIn</a></li>
                            </ul>
                        </div><!-- end form-content -->
                    </div><!-- end form-box -->
                </div><!-- end col-lg-3 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end user-area -->
    <!-- ================================
        END USER AREA
    ================================= -->
</template>

<script>
    export default {
        name: "Profile"
    }
</script>

<style lang="scss" scoped>
   @import "../../sass/partials/_variables.scss";

    b {
        display: inline-block;
        width: 100px;
        color: $theme-color;
    }

    .list-items {
        li {
            color: #333333
        }
    }

    .comment__meta {
        color: $theme-color;
    }
</style>
