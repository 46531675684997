<template>
    <!-- ================================
        START BREADCRUMB AREA
    ================================= -->
    <section class="breadcrumb-area bread-bg-9">
        <div class="breadcrumb-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-content text-center">
                            <div class="section-heading">
                                <h2 class="sec__title">Add New Post</h2>
                            </div>
                        </div><!-- end breadcrumb-content -->
                    </div><!-- end col-lg-12 -->
                </div><!-- end row -->
            </div><!-- end container -->
        </div><!-- end breadcrumb-wrap -->
        <div class="bread-svg-box">
            <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
        </div><!-- end bread-svg -->
    </section><!-- end breadcrumb-area -->
    <!-- ================================
        END BREADCRUMB AREA
    ================================= -->

    <!-- ================================
        START FORM AREA
    ================================= -->
    <section class="listing-form section--padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="form-box">
                        <div class="form-title-wrap">
                            <h3 class="title"><i class="la la-gear mr-2 text-gray"></i>Add New Post</h3>
                        </div><!-- form-title-wrap -->
                        <div class="form-content contact-form-action">
                            <form method="post" class="row">
                                <div class="col-lg-12">
                                    <div class="input-box">
                                        <label class="label-text">Title</label>
                                        <div class="form-group">
                                            <span class="la la-briefcase form-icon"></span>
                                            <input class="form-control" type="text" name="text" placeholder="Enter title here">
                                        </div>
                                    </div>
                                </div><!-- end col-lg-12 -->
                                <div class="col-lg-12">
                                    <div class="input-box">
                                        <label class="label-text">Description</label>
                                        <div class="form-group">
                                            <span class="la la-pencil form-icon"></span>
                                            <textarea class="message-control form-control" id="editor" name="message">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</textarea>
                                        </div>
                                    </div>
                                </div><!-- end col-lg-12 -->
                            </form>
                        </div><!-- end form-content -->
                    </div><!-- end form-box -->
                    <div class="submit-box">
                        <div class="btn-box pt-3">
                            <button type="submit" class="theme-btn">Add New Post <i class="la la-arrow-right ml-1"></i></button>
                        </div>
                    </div><!-- end submit-box -->
                </div><!-- end col-lg-8 -->
                <div class="col-lg-4">
                    <div class="form-box">
                        <div class="form-title-wrap">
                            <h3 class="title"><i class="la la-gear mr-2 text-gray"></i>Add Category</h3>
                        </div><!-- form-title-wrap -->
                        <div class="form-content contact-form-action">
                            <form method="post">
                                <div class="input-box">
                                    <label class="label-text">Categories</label>
                                    <div class="form-group mb-0">
                                        <span class="la la-briefcase form-icon"></span>
                                        <input class="form-control" type="text" name="text" placeholder="Add category">
                                    </div>
                                </div>
                            </form>
                        </div><!-- end form-content -->
                    </div><!-- end form-box -->
                    <div class="form-box">
                        <div class="form-title-wrap">
                            <h3 class="title"><i class="la la-gear mr-2 text-gray"></i>Add Tags</h3>
                        </div><!-- form-title-wrap -->
                        <div class="form-content contact-form-action">
                            <form method="post">
                                <div class="input-box">
                                    <label class="label-text">Tags (separate tags with commas)</label>
                                    <div class="form-group mb-0">
                                        <span class="la la-briefcase form-icon"></span>
                                        <input class="form-control" type="text" name="text" placeholder="Example: travel, nature">
                                    </div>
                                </div>
                            </form>
                        </div><!-- end form-content -->
                    </div><!-- end form-box -->
                    <div class="form-box">
                        <div class="form-title-wrap">
                            <h3 class="title"><i class="la la-photo mr-2 text-gray"></i>Featured Image</h3>
                        </div><!-- form-title-wrap -->
                        <div class="form-content">
                            <form method="post">
                                <div class="file-upload-wrap file-upload-wrap-3">
                                    <input type="file" name="files[]" class="multi file-upload-input with-preview" multiple maxlength="3">
                                    <span class="file-upload-text"><i class="la la-upload mr-2"></i>Upload image</span>
                                </div>
                            </form>
                        </div><!-- end form-content -->
                    </div><!-- end form-box -->
                </div><!-- end col-lg-4 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end listing-form -->
    <!-- ================================
        END FORM AREA
    ================================= -->

</template>

<script>
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

    export default {
        name: "AddNewPost",

        mounted(){
            ClassicEditor
                .create( document.querySelector( '#editor' ) )
                .catch( error => {
                    console.error( error );
                } );
        }
    }
</script>

<style scoped>

</style>
