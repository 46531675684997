import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import "jquery-ui-bundle"
import "bootstrap"
import "bootstrap-select"
import "moment"
import "daterangepicker"
import "owl.carousel"
import "@fancyapps/fancybox"
import "typeface-roboto"
import "@ckeditor/ckeditor5-build-classic"
import "jquery-multifile"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-select/dist/css/bootstrap-select.min.css"
import "line-awesome/dist/line-awesome/css/line-awesome.min.css"
import "owl.carousel/dist/assets/owl.carousel.min.css"
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css"
import "daterangepicker/daterangepicker.css"
import "animate.css/animate.min.css"
import "jquery-ui-bundle/jquery-ui.theme.min.css"

import "../sass/style.scss"

import "flag-icon-css/css/flag-icon.min.css"
import "vue-select/src/scss/vue-select.scss"
import 'vue-slider-component/theme/default.css'

createApp(App).use(store).use(router).mount('#app')
