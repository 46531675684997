<template>

    <!-- ================================
        START BREADCRUMB AREA
    ================================= -->
    <section class="breadcrumb-area bread-bg-2" style="padding-bottom: 0 !important;">
        <div class="breadcrumb-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-btn">
                            <div class="btn-box">
                                <a class="theme-btn" data-fancybox="video" data-src="https://www.youtube.com/watch?v=0GZSfBuhf6Y"
                                   data-speed="700">
                                    <i class="la la-video-camera mr-2"></i>Video
                                </a>
                                <a class="theme-btn" data-src="@/assets/trizen/destination-img.jpg"
                                   data-fancybox="gallery"
                                   data-caption="Showing image - 01"
                                   data-speed="700">
                                    <i class="la la-photo mr-2"></i> Photos
                                </a>
                                <a class="theme-btn ml-3" data-fancybox="" data-options="{'iframe': 'css' : 'width' : '80%', 'height' : '80%'}}}"
                                   href="https://www.google.com/maps/search/?api=1&amp;query=china">
                                    <i class="la la-map-marked-alt mr-2"></i>Map
                                </a>
                            </div>
                            <a class="d-none"
                               data-fancybox="gallery"
                               data-src="@/assets/trizen/destination-img2.jpg"
                               data-caption="Showing image - 02"
                               data-speed="700"></a>
                            <a class="d-none"
                               data-fancybox="gallery"
                               data-src="@/assets/trizen/destination-img3.jpg"
                               data-caption="Showing image - 03"
                               data-speed="700"></a>
                            <a class="d-none"
                               data-fancybox="gallery"
                               data-src="@/assets/trizen/destination-img4.jpg"
                               data-caption="Showing image - 04"
                               data-speed="700"></a>
                        </div><!-- end breadcrumb-btn -->
                    </div><!-- end col-lg-12 -->
                </div><!-- end row -->
            </div><!-- end container -->
        </div><!-- end breadcrumb-wrap -->
    </section><!-- end breadcrumb-area -->
    <!-- ================================
        END BREADCRUMB AREA
    ================================= -->

    <!-- ================================
        START TOUR DETAIL AREA
    ================================= -->
    <section class="tour-detail-area padding-bottom-90px">
        <div class="single-content-box">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="single-content-wrap padding-top-60px">
                            <div id="description" class="page-scroll">
                                <div class="single-content-item pb-4">

                                    <div class="d-flex flex-wrap">
                                        <h3 class="title font-size-26">China</h3>
                                        <p class="badge badge-success text-white font-size-13 font-weight-normal ml-2"
                                           data-toggle="tooltip" data-placement="top" title="Price typically ranges from 100-2000 dollars."
                                           style="margin-top: 0.4em; margin-bottom: 0.5em;">$$</p>
                                    </div>


                                    <div class="d-flex flex-wrap align-items-center pt-2">
                                        <span class="ratings d-flex align-items-center mr-1">
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star"></i>
                                            <i class="la la-star-o"></i>
                                        </span>
                                            <span class="badge badge-warning text-white font-size-16">Good</span>
                                            <span class="rating__text ml-2">(70694 Reviews)</span>
                                        </div>
                                </div><!-- end single-content-item -->
                                <div class="section-block"></div>
                                <div class="single-content-item py-4">
                                    <div class="row">
                                        <div class="col-6 col-md-4"
                                             data-toggle="tooltip" data-placement="top" title="This is a good place for nightlife.">
                                            <div class="single-tour-feature d-flex align-items-center mb-3">
                                                <div class="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                                    <i class="la la-glass"></i>
                                                </div>
                                                <div class="single-feature-titles">
                                                    <h3 class="title font-size-15 font-weight-medium">Nightlife</h3>
                                                </div>
                                            </div><!-- end single-tour-feature -->
                                        </div><!-- end col-lg-4 -->
                                        <div class="col-6 col-md-4"
                                             data-toggle="tooltip" data-placement="top" title="This is a good place for beaches.">
                                            <div class="single-tour-feature d-flex align-items-center mb-3">
                                                <div class="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                                    <i class="la la-umbrella-beach"></i>
                                                </div>
                                                <div class="single-feature-titles">
                                                    <h3 class="title font-size-15 font-weight-medium">Beaches</h3>
                                                </div>
                                            </div><!-- end single-tour-feature -->
                                        </div><!-- end col-lg-4 -->
                                        <div class="col-6 col-md-4"
                                             data-toggle="tooltip" data-placement="top" title="This is a good place for history.">
                                            <div class="single-tour-feature d-flex align-items-center mb-3">
                                                <div class="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                                    <i class="la la-landmark"></i>
                                                </div>
                                                <div class="single-feature-titles">
                                                    <h3 class="title font-size-15 font-weight-medium">History</h3>
                                                </div>
                                            </div><!-- end single-tour-feature -->
                                        </div><!-- end col-lg-4 -->
                                        <div class="col-6 col-md-4"
                                             data-toggle="tooltip" data-placement="top" title="This is a good place for tourism.">
                                            <div class="single-tour-feature d-flex align-items-center mb-3">
                                                <div class="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                                    <i class="la la-monument"></i>
                                                </div>
                                                <div class="single-feature-titles">
                                                    <h3 class="title font-size-15 font-weight-medium">Tourism</h3>
                                                </div>
                                            </div><!-- end single-tour-feature -->
                                        </div><!-- end col-lg-4 -->
                                        <div class="col-6 col-md-4"
                                             data-toggle="tooltip" data-placement="top" title="This is a good place for quality of life.">
                                            <div class="single-tour-feature d-flex align-items-center mb-3">
                                                <div class="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                                    <i class="lar la-heart"></i>
                                                </div>
                                                <div class="single-feature-titles">
                                                    <h3 class="title font-size-15 font-weight-medium">Quality of Life</h3>
                                                </div>
                                            </div><!-- end single-tour-feature -->
                                        </div><!-- end col-lg-4 -->
                                        <div class="col-6 col-md-4"
                                             data-toggle="tooltip" data-placement="top" title="This is a good place for adventure.">
                                            <div class="single-tour-feature d-flex align-items-center mb-3">
                                                <div class="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                                    <i class="la la-hiking"></i>
                                                </div>
                                                <div class="single-feature-titles">
                                                    <h3 class="title font-size-15 font-weight-medium">Adventure</h3>
                                                </div>
                                            </div><!-- end single-tour-feature -->
                                        </div><!-- end col-lg-4 -->
                                        <div class="col-6 col-md-4"
                                             data-toggle="tooltip" data-placement="top" title="This is a good place for culture.">
                                            <div class="single-tour-feature d-flex align-items-center mb-3">
                                                <div class="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                                    <i class="la la-users"></i>
                                                </div>
                                                <div class="single-feature-titles">
                                                    <h3 class="title font-size-15 font-weight-medium">Culture</h3>
                                                </div>
                                            </div><!-- end single-tour-feature -->
                                        </div><!-- end col-lg-4 -->
                                        <div class="col-6 col-md-4"
                                             data-toggle="tooltip" data-placement="top" title="This is a good place for entertainment.">
                                            <div class="single-tour-feature d-flex align-items-center mb-3">
                                                <div class="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                                    <i class="la la-music"></i>
                                                </div>
                                                <div class="single-feature-titles">
                                                    <h3 class="title font-size-15 font-weight-medium">Entertainment</h3>
                                                </div>
                                            </div><!-- end single-tour-feature -->
                                        </div><!-- end col-lg-4 -->
                                    </div><!-- end row -->
                                </div><!-- end single-content-item -->
                                <div class="section-block"></div>
                                <div class="single-content-item padding-top-30px padding-bottom-30px">
                                    <h3 class="title font-size-20 pb-3">Description</h3>
                                    <p>China (the People's Republic of China), as one of the four ancient civilizations in the world, has a long history and has formed a profound and colorful Chinese culture, from the far-reaching Confucian culture to the unique-style Chinese calligraphy and painting; from the distinctive Chinese tea culture to the world-famous Chinese Kung Fu, All and all are amazing and making you yearning for a visit.
                                        <br><br>The land of China, with its vast territory and beautiful scenery, offers you so much to see and explore. From the ancient Great Wall to the modern skyscrapers in metropolis; from the Yellow River and the Yangtze River to the roof of the world, from the beaches of Sanya to the snow county of the Northern China, China will give you unlimited experiences.</p>
                                </div><!-- end single-content-item -->
                                <div class="section-block"></div>
                            </div><!-- end description -->

                            <div id="reviews" class="page-scroll">
                                <div class="single-content-item padding-top-30px padding-bottom-30px">
                                    <h3 class="title font-size-20">Reviews</h3>
                                    <div class="review-container padding-top-10px">
                                        <div class="row align-items-center">
                                            <div class="col-6 col-md-4" style="margin: 0 auto">
                                                <div class="review-summary">
                                                    <h2>4.5<span>/5</span></h2>
                                                    <p>Excellent</p>
                                                    <span>Based on 4 reviews</span>
                                                </div>
                                            </div><!-- end col-lg-4 -->
                                            <div class="col-md-8">
                                                <div class="review-bars">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="input-box">
                                                                <label class="label-text">Race</label>
                                                                <div class="form-group">
                                                                    <div class="select-contain w-auto">
                                                                        <select class="select-contain-select" v-model="searchRace">
                                                                            <option value="all">All Races</option>
                                                                            <option v-for="race in races" :key="race.id" v-bind:value="race.id">{{ race.name }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div><!-- end col-lg-6 -->
                                                        <div class="col-6">
                                                            <div class="input-box">
                                                                <label class="label-text">Gender</label>
                                                                <div class="form-group">
                                                                    <div class="select-contain w-auto">
                                                                        <select class="select-contain-select" v-model="searchGender">
                                                                            <option value="all">All Genders</option>
                                                                            <option v-for="gender in genders" :key="gender.id" v-bind:value="gender.id">{{ gender.name }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div><!-- end col-lg-6 -->
                                                        <div class="col-6">
                                                            <div class="input-box">
                                                                <label class="label-text">Religion</label>
                                                                <div class="form-group">
                                                                    <div class="select-contain w-auto">
                                                                        <select class="select-contain-select" v-model="searchReligion">
                                                                            <option value="all">All Religions</option>
                                                                            <option v-for="religion in religions" :key="religion.id" v-bind:value="religion.id">{{ religion.name }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div><!-- end col-lg-6 -->
                                                        <div class="col-6">
                                                            <div class="input-box">
                                                                <label class="label-text">Sexuality</label>
                                                                <div class="form-group">
                                                                    <div class="select-contain w-auto">
                                                                        <select class="select-contain-select" v-model="searchOrientation">
                                                                            <option value="all">All Sexualities</option>
                                                                            <option v-for="orientation in orientations" :key="orientation.id" v-bind:value="orientation.id">{{ orientation.name }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div><!-- end col-lg-6 -->
                                                        <div class="col-6">
                                                            <div class="input-box price-slider-amount">
                                                                <label class="label-text">Age Range</label>
                                                                <AgePicker v-model="searchAge" class="mb-2 ml-2"></AgePicker>
                                                                <p type="text" class="amounts" style="width: 90px; background-color: rgba(40, 125, 250, 0.04);">{{ displayAge }}</p>
                                                            </div>
                                                        </div><!-- end col-lg-6 -->
                                                    </div><!-- end row -->
                                                </div>
                                            </div><!-- end col-lg-8 -->
                                        </div>
                                    </div>
                                </div><!-- end single-content-item -->
                                <div class="section-block"></div>
                            </div><!-- end reviews -->
                            <div class="review-box">
                                <div class="single-content-item padding-top-40px">
                                    <h3 class="title font-size-20">Showing 2 reviews</h3>
                                    <div class="comments-list padding-top-50px">
                                        <div class="comment">
                                            <a href="/profile" class="comment-avatar">
                                                <img class="avatar__img" alt="" src="@/assets/trizen/team8.jpg">
                                            </a>
                                            <div class="comment-body">
                                                <a href="/profile" class="meta-data">
                                                    <h3 class="comment__author">Johnny Depp</h3>
                                                    <div class="meta-data-inner d-flex">
                                                    <span class="ratings d-flex align-items-center mr-1">
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star-o"></i>
                                                        <i class="la la-star-o"></i>
                                                        <i class="la la-star-o"></i>
                                                    </span>
                                                        <p class="comment__date">April 5, 2019</p>
                                                    </div>
                                                </a>
                                                <p class="comment-content">
                                                    This trip was not the best experience. The flight was very long, and once I got there the country was so big I didn't even know which direction I was going in.
                                                    The people were friendly, but I didn't speak the language so I couldn't understand what they were saying. The food also wasn't my favorite either, I wish they had
                                                    more American based restaurants there. However, the music was very lively and I enjoyed walking through the streets. My biggest complaint is there are no Panda Expresses,
                                                    what's up with that?
                                                </p>
                                                <div class="comment-reply d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <a class="theme-btn" href="#" data-toggle="modal" data-target="#replayPopupForm">
                                                            <span class="la la-mail-reply mr-1"></span>Reply
                                                        </a>
                                                        <a class="theme-btn ml-2" href="#" data-toggle="modal" data-target="#replayPopupForm">
                                                            <span class="la la-close mr-1"></span>Delete
                                                        </a>
                                                    </div>
                                                    <div class="reviews-reaction">
                                                        <a href="#" class="comment-like"><i class="la la-thumbs-up"></i> 13</a>
                                                        <a href="#" class="comment-dislike"><i class="la la-thumbs-down"></i> 2</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- end comments -->
                                        <div class="comment comment-reply-item">
                                            <a href="/profile" class="comment-avatar">
                                                <img class="avatar__img" alt="" src="@/assets/trizen/img16.jpg">
                                            </a>
                                            <div class="comment-body">
                                                <a href="/profile" class="meta-data">
                                                    <h3 class="comment__author">China</h3>
                                                    <div class="meta-data-inner d-flex">
                                                        <p class="comment__date">April 5, 2019</p>
                                                    </div>
                                                </a>
                                                <p class="comment-content">
                                                    We're sorry that you had a less than ideal experience in our country. However, a lot of these complaints, such as the long flight, are not our fault (if your flight is long,
                                                    maybe don't fly to a country so far away or raise this concern to your airliner). Again, you not being able to speak the language of the country you are visiting is not
                                                    a fault on our end. As to why there are no Panda Expresses: you do know that it is not real Chinese food right?
                                                </p>
                                                <div class="comment-reply d-flex align-items-center justify-content-end">
                                                    <div class="reviews-reaction">
                                                        <a href="#" class="comment-like"><i class="la la-thumbs-up"></i> 13</a>
                                                        <a href="#" class="comment-dislike"><i class="la la-thumbs-down"></i> 2</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- end comments -->
                                        <div class="comment">
                                            <a href="/profile" class="comment-avatar">
                                                <img class="avatar__img" alt="" src="@/assets/trizen/team10.jpg">
                                            </a>
                                            <div class="comment-body">
                                                <a href="/profile" class="meta-data">
                                                    <h3 class="comment__author">Jackie Chan</h3>
                                                    <div class="meta-data-inner d-flex">
                                                    <span class="ratings d-flex align-items-center mr-1">
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                        <i class="la la-star"></i>
                                                    </span>
                                                        <p class="comment__date">March 2, 2019</p>
                                                    </div>
                                                </a>
                                                <p class="comment-content">
                                                    I had the most wonderful time in China! It was so nice being able to meet all of the locals and conversate with them about their day.
                                                    Everyone was so friendly to me, even though the country is huge, they were always good with directions and pointing me out to where everything is when I was lost.
                                                    The flight was indeed long, but I was able to catch some much needed rest before my fun-filled week. I explored the city of Beijing, saw the Great Wall, ate at
                                                    exquisite resturaunts, and soaked in the beautiful history and architecture of the country. I can't wait to go back again.
                                                </p>
                                                <div class="comment-reply d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <a class="theme-btn" href="#" data-toggle="modal" data-target="#replayPopupForm">
                                                            <span class="la la-mail-reply mr-1"></span>Reply
                                                        </a>
                                                        <a class="theme-btn ml-2" href="#" data-toggle="modal" data-target="#replayPopupForm">
                                                            <span class="la la-close mr-1"></span>Delete
                                                        </a>
                                                    </div>
                                                    <div class="reviews-reaction">
                                                        <a href="#" class="comment-like"><i class="la la-thumbs-up"></i> 13</a>
                                                        <a href="#" class="comment-dislike"><i class="la la-thumbs-down"></i> 2</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- end comments -->
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="btn-box mt-3 text-center">
                                                    <ul class="pagination justify-content-center pb-1 pt-3">
                                                        <li class="page-item">
                                                            <a class="page-link page-link-nav" href="#" aria-label="Previous">
                                                                <span aria-hidden="true"><i class="la la-angle-left"></i></span>
                                                                <span class="sr-only">Previous</span>
                                                            </a>
                                                        </li>
                                                        <li class="page-item active">
                                                            <a class="page-link page-link-nav" href="#">1 <span class="sr-only">(current)</span></a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link page-link-nav" href="#">2</a></li>
                                                        <li class="page-item"><a class="page-link page-link-nav" href="#">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link page-link-nav" href="#" aria-label="Next">
                                                                <span aria-hidden="true"><i class="la la-angle-right"></i></span>
                                                                <span class="sr-only">Next</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <p class="font-size-13 pt-2">Showing 1 - 5 of 180 Reviews</p>
                                                </div><!-- end btn-box -->
                                            </div><!-- end col-lg-12 -->
                                        </div><!-- end row -->
                                    </div><!-- end comments-list -->
                                    <div class="comment-forum padding-top-40px">
                                        <div class="form-box" style="border-radius: 8px">
                                            <div class="form-title-wrap">
                                                <h3 class="title mt-1 ml-1">Leave a Review</h3>
                                            </div><!-- form-title-wrap -->
                                            <div class="form-content">
                                                <div class="rate-option pl-2">
                                                    <div class="rate-option-item d-flex">
                                                        <div class="rate-stars-option">
                                                            <input type="radio" id="lst1" name="stars" value="1" v-model="reviewStars">
                                                            <label for="lst1"></label>
                                                            <input type="radio" id="lst2" name="stars" value="2" v-model="reviewStars">
                                                            <label for="lst2"></label>
                                                            <input type="radio" id="lst3" name="stars" value="3" v-model="reviewStars">
                                                            <label for="lst3"></label>
                                                            <input type="radio" id="lst4" name="stars" value="4" v-model="reviewStars">
                                                            <label for="lst4"></label>
                                                            <input type="radio" id="lst5" name="stars" value="5" v-model="reviewStars">
                                                            <label for="lst5"></label>
                                                        </div>
                                                        <p style="font-size: 14px; margin-top: 2px; margin-left: 4px;">Select your rating</p>
                                                    </div>
                                                </div><!-- end rate-option -->
                                                <div class="contact-form-action">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="input-box ml-2 mr-4">
                                                                <div class="form-group mt-1">
                                                                    <span class="la la-pencil form-icon"></span>
                                                                    <textarea class="message-control form-control" style="height: 200px;" name="message" placeholder="Write your review"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 mt-2 ml-2">
                                                            <div class="btn-box">
                                                                <button type="button" class="theme-btn">Post Review</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><!-- end contact-form-action -->
                                            </div><!-- end form-content -->
                                        </div><!-- end form-box -->
                                    </div><!-- end comment-forum -->
                                </div><!-- end single-content-item -->
                            </div><!-- end review-box -->
                        </div><!-- end single-content-wrap -->
                    </div><!-- end col-lg-8 -->
                    <div class="col-lg-4">
                        <div class="sidebar single-content-sidebar mb-0 ml-3">
                            <div class="sidebar-widget single-content-widget d-none d-lg-block">
                                <div class="sidebar-widget-item">
                                    <div class="sidebar-book-title-wrap mb-3">
                                        <h3>Asia</h3>
                                    </div>
                                </div><!-- end sidebar-widget-item -->
                                <div class="btn-box pt-2">
                                    <a href="tour-booking.html" class="theme-btn text-center w-100 mb-2"><i class="la la-pencil mr-2 font-size-18"></i>Leave a Review</a>
                                    <a href="#" class="theme-btn text-center w-100 theme-btn-transparent"
                                       data-toggle="tooltip" data-placement="bottom" title="Save for Later"><i class="la la-heart-o mr-2"></i>Add to Wishlist</a>
                                </div>
                            </div><!-- end sidebar-widget -->

                            <div class="responsive-title">
                                <h2 class="title stroke-shape pb-3 mt-2 mb-4">You might also like</h2>
                            </div>

                            <div class="responsive-column-alt mt-2">
                                <div class="card-item trending-card">
                                    <div class="card-img">
                                        <a href="tour-details.html" class="d-block">
                                            <img src="@/assets/trizen/img19.jpg" alt="Destination-img">
                                            <span class="badge">Popular</span>
                                        </a>

                                        <div class="add-to-wishlist icon-element" data-toggle="tooltip" data-placement="top" title="Save for Later" style="z-index: 999">
                                            <i class="la la-heart-o"></i>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <h3 class="card-title"><a href="tour-details.html">Singapore</a></h3>
                                        <p class="card-meta">Asia</p>
                                        <div class="card-rating d-flex align-items-center pt-0">
                                                <span class="ratings d-flex align-items-center mr-1">
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star"></i>
                                                </span>

                                            <span class="badge text-white mr-2">Excellent</span>
                                            <span class="rating__text">(70694 Reviews)</span>
                                        </div>
                                        <div class="card-price d-flex align-items-center">
                                            <a href="tour-details.html" class="btn-text">View details<i class="la la-angle-right"></i></a>
                                        </div>
                                    </div>
                                </div><!-- end card-item -->
                            </div><!-- end col-lg-4 -->

                            <div class="responsive-column-alt mt-2">
                                <div class="card-item trending-card">
                                    <div class="card-img">
                                        <a href="tour-details.html" class="d-block">
                                            <img src="@/assets/trizen/img8.jpg" alt="Destination-img">
                                            <span class="badge">Popular</span>
                                        </a>

                                        <div class="add-to-wishlist icon-element" data-toggle="tooltip" data-placement="top" title="Save for Later" style="z-index: 999">
                                            <i class="la la-heart-o"></i>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <h3 class="card-title"><a href="tour-details.html">Japan</a></h3>
                                        <p class="card-meta">Asia</p>
                                        <div class="card-rating d-flex align-items-center pt-0">
                                                <span class="ratings d-flex align-items-center mr-1">
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star-o"></i>
                                                </span>

                                                <span class="badge text-white mr-2">Good</span>
                                                <span class="rating__text">(70694 Reviews)</span>
                                        </div>
                                        <div class="card-price d-flex align-items-center">
                                            <a href="tour-details.html" class="btn-text">View details<i class="la la-angle-right"></i></a>
                                        </div>
                                    </div>
                                </div><!-- end card-item -->
                            </div><!-- end col-lg-4 -->

                            <div class="responsive-column-alt mt-2">
                                <div class="card-item trending-card">
                                    <div class="card-img">
                                        <a href="tour-details.html" class="d-block">
                                            <img src="@/assets/trizen/img18.jpg" alt="Destination-img">
                                        </a>

                                        <div class="add-to-wishlist icon-element" data-toggle="tooltip" data-placement="top" title="Save for Later" style="z-index: 999">
                                            <i class="la la-heart-o"></i>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <h3 class="card-title"><a href="tour-details.html">Egypt</a></h3>
                                        <p class="card-meta">Africa</p>
                                        <div class="card-rating d-flex align-items-center pt-0">
                                                <span class="ratings d-flex align-items-center mr-1">
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star"></i>
                                                    <i class="la la-star-o"></i>
                                                    <i class="la la-star-o"></i>
                                                </span>

                                            <span class="badge text-white mr-2">Average</span>
                                            <span class="rating__text">(70694 Reviews)</span>
                                        </div>
                                        <div class="card-price d-flex align-items-center">
                                            <a href="tour-details.html" class="btn-text">View details<i class="la la-angle-right"></i></a>
                                        </div>
                                    </div>
                                </div><!-- end card-item -->
                            </div><!-- end col-lg-4 -->

                        </div><!-- end sidebar -->
                    </div><!-- end col-lg-4 -->
                </div><!-- end row -->
            </div><!-- end container -->
        </div><!-- end single-content-box -->
    </section><!-- end tour-detail-area -->
    <!-- ================================
        END TOUR DETAIL AREA
    ================================= -->

    <!-- end modal-shared -->
    <div class="modal-popup">
        <div class="modal fade" id="replayPopupForm" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title title" id="exampleModalLongTitle3">Replay to review</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="la la-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="contact-form-action">
                            <form method="post">
                                <div class="input-box">
                                    <div class="form-group mb-0">
                                        <i class="la la-pencil form-icon"></i>
                                        <textarea class="message-control form-control" name="message" placeholder="Write message here..."></textarea>
                                    </div>
                                </div>
                            </form>
                        </div><!-- end contact-form-action -->
                    </div>
                    <div class="modal-footer border-top-0 pt-0">
                        <button type="button" class="btn font-weight-bold font-size-15 color-text-2 mr-2" data-dismiss="modal">Cancel</button>
                        <button type="button" class="theme-btn theme-btn-small">Replay</button>
                    </div>
                </div>
            </div>
        </div>
    </div><!-- end modal-popup -->

    <!-- end modal-shared -->
    <div class="modal-popup">
        <div class="modal fade" id="sharePopupForm" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title title" id="exampleModalLongTitle4">Share this tour</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="la la-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="copy-to-clipboard-wrap">
                            <div class="copy-to-clipboard">
                                <div class="contact-form-action d-flex align-items-center">
                                    <span class="text-success-message">Copied!</span>
                                    <input type="text" class="form-control copy-input" value="https://www.tripstar.com/share/101WxMB0oac1hVQQ==/">
                                    <div class="btn-box">
                                        <button class="theme-btn theme-btn-light copy-text">Copy</button>
                                    </div>
                                </div>
                            </div><!-- end copy-to-clipboard -->
                            <ul class="social-profile text-center">
                                <li><a href="#"><i class="lab la-facebook-f"></i></a></li>
                                <li><a href="#"><i class="lab la-twitter"></i></a></li>
                                <li><a href="#"><i class="lab la-instagram"></i></a></li>
                                <li><a href="#"><i class="lab la-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><!-- end modal-popup -->
</template>

<script>
    import * as constants from "../constants";
    import AgePicker from "@/components/pickers/AgePicker"

    export default {
        name: "Country",
        components: {
            AgePicker
        },

        data(){
            return {
                races: constants.RACES,
                genders: constants.GENDERS,
                religions: constants.RELIGIONS,
                orientations: constants.ORIENTATIONS,

                searchAge: [18, 34],
                searchRace: 'all',
                searchGender: 'all',
                searchReligion: 'all',
                searchOrientation: 'all',

                reviewStars: 0
            }
        },

        computed: {
            displayAge(){
                return this.searchAge[0] + " - " + this.searchAge[1]
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../sass/partials/variables";

    .meta-data {
        .comment__author {
            transition: 0.3s;
        }

        .comment__date {
            color: $theme-color-4;
            transition: 0.3s;
        }

        &:hover {
            .comment__author {
                color: $theme-color-2;
            }

            .comment__date {
                color: $theme-color-2;
            }
        }
    }

    .responsive-title {
        .title {
            font-size: 22px;
        }

        @media #{$tab_device} {
            text-align: center;

            .title {
                font-size: 32px;
            }

            .stroke-shape {
                &::before,
                &::after{
                    display: none;
                }
            }
        }
        @media #{$large_mobile} {
            text-align: center;

            .title {
                font-size: 28px;
            }

            .stroke-shape {
                &::before,
                &::after{
                    display: none;
                }
            }
        }
        @media #{$small_mobile} {
            text-align: center;

            .title {
                font-size: 24px;
            }

            .stroke-shape {
                &::before,
                &::after{
                    display: none;
                }
            }
        }
    }
</style>
