export const RACES = [
    {id: "asian", name: "Asian"},
    {id: "native", name: "American Indian or Alaska Native"},
    {id: "black", name: "Black / African American"},
    {id: "hispanic", name: "Hispanic / Latinx"},
    {id: "hawaiian", name: "Native Hawaiian / Pacific Islander"},
    {id: "white", name: "White"},
];

export const GENDERS = [
    {id: "male", name: "Male"},
    {id: "female", name: "Female"},
    {id: "transgender", name: "Transgender"}
];

export const RELIGIONS = [
    {id: "buddhist", name: "Buddhist"},
    {id: "christian", name: "Christian"},
    {id: "hindu", name: "Hindu"},
    {id: "jewish", name: "Jewish"},
    {id: "muslim", name: "Muslim"},
    {id: "none", name: "Non-religious"},
    {id: "ethnic", name: "Traditional / Ethnic"}
];

export const ORIENTATIONS = [
    {id: "gay", name: "Gay"},
    {id: "lesbian", name: "Lesbian"},
    {id: "bisexual", name: "Bisexual"},
    {id: "queer", name: "Queer"},
    {id: "straight", name: "Straight"}
];

export const CONTINENTS = [
    {id: "asia", name: "Asia"},
    {id: "africa", name: "Africa"},
    {id: "americanorth", name: "North America"},
    {id: "americasouth", name: "South America"},
    {id: "europe", name: "Europe"},
    {id: "australia", name: "Australia"}
];
