<template>
    <vue-slider :min="18"
                :max="80"
                :enable-cross="false"
                :useKeyboard="false"
                :rail-style="{backgroundColor: 'rgba(128,137,150,.2)', borderRadius: '6px', height: '5px'}"
                :process-style="{ backgroundColor: '#287dfa', height: '110%' }"
                :tooltip-style="{ backgroundColor: '#287dfa', borderColor: '#287dfa' }">

        <template v-slot:dot>
            <div class="slider-dot"></div>
        </template>
    </vue-slider>

</template>

<script>
    import VueSlider from 'vue-slider-component'

    export default {
        name: "AgePicker",
        components: {
            VueSlider
        }
    }
</script>

<style scoped>
    .slider-dot {
        background-color: #287dfa;
        -webkit-box-shadow: 0 0 20px rgba(0,0,0,.2);
        -moz-box-shadow: 0 0 20px rgba(0,0,0,.2);
        box-shadow: 0 0 20px rgba(0,0,0,.2);
        border: 5px solid #fff;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        height: 18px;
        width: 18px;
        top: -12px;
        cursor: ew-resize;
        outline: 0;
        transition: 0.3s;
    }

    .slider-dot:hover {
        cursor: ew-resize;
        transform: scale(1.2);
    }
</style>
