<template>
    <section class="footer-area section-bg padding-top-50px padding-bottom-20px">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 responsive-column">
                    <div class="footer-item">
                        <div class="footer-logo padding-bottom-20px">
                            <a href="/" class="foot__logo"><img src="@/assets/img/logo-white.png" alt="logo"></a>
                        </div><!-- end logo -->
                        <p class="footer__desc">Identity-based travel to help <br> you travel smarter.</p>
                        <ul class="list-items pt-3">
                            <li><a href="#">contact@trekwisely.com</a></li>
                        </ul>
                    </div><!-- end footer-item -->
                </div><!-- end col-lg-3 -->
                <div class="col-lg-2 responsive-column" style="margin-right: 3em;">
                    <div class="footer-item">
                        <h4 class="title curve-shape pb-3 margin-bottom-20px" data-text="curvs">Company</h4>
                        <ul class="list-items">
                            <li><a href="/mission">Mission</a></li>
                            <li><a href="/guidelines">Community Guidelines</a></li>
                            <li><a href="/privacy">Privacy Policy</a></li>
                            <li><a href="/jobs">Job Applications</a></li>
                        </ul>
                    </div><!-- end footer-item -->
                </div><!-- end col-lg-3 -->
                <div class="col-lg-3 responsive-column">
                    <div class="footer-item">
                        <h4 class="title curve-shape pb-3 margin-bottom-20px" data-text="curvs">Travel & Review</h4>
                        <ul class="list-items">
                            <li><a href="#">Create An Account</a></li>
                            <li><a href="#">Claim A Country</a></li>
                            <li><a href="#">Leave a Review</a></li>
                            <li><a href="#">Travel Articles</a></li>
                        </ul>
                    </div><!-- end footer-item -->
                </div><!-- end col-lg-3 -->
            </div><!-- end row -->
            <div class="section-block mt-n4"></div>
        </div><!-- end container -->
        <div class="container mt-n3">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="copy-right padding-top-30px">
                        <p class="copy__desc list--items">
                            &copy; {{new Date().getFullYear()}} TrekWisely, Inc. All Rights Reserved. Built by <a href="https://burtonalgorithms.com" target="_blank">Burton Algorithms, Inc.</a>
                        </p>
                    </div><!-- end copy-right -->
                </div><!-- end col-lg-7 -->
                <div class="col-lg-5">
                    <div class="copy-right-content d-flex align-items-center justify-content-end padding-top-30px footer-social-box">
                        <ul class="social-profile">
                            <li><a href="#"><i class="lab la-facebook-f"></i></a></li>
                            <li><a href="#"><i class="lab la-twitter"></i></a></li>
                            <li><a href="#"><i class="lab la-instagram"></i></a></li>
                            <li><a href="#"><i class="lab la-linkedin-in"></i></a></li>
                        </ul>
                    </div><!-- end copy-right-content -->
                </div><!-- end col-lg-5 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end footer-area -->
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>
