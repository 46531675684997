<template class="header-area">
    <div class="header-menu-wrapper padding-right-100px padding-left-100px">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="menu-wrapper justify-content-between">
                        <div class="logo" :class="{'mr-auto': showSearch, 'mb-3': showSearch, 'mb-lg-0': showSearch, 'pr-4': showSearch}">
                            <a href="/"><img src="@/assets/img/logo.png" alt="logo"></a>
                            <div class="menu-toggler">
                                <i class="la la-bars"></i>
                                <i class="la la-times"></i>
                            </div><!-- end menu-toggler -->
                        </div><!-- end logo -->

                        <div class="main-menu-content pr-0 ml-0" v-if="!showSearch">
                            <nav>
                                <ul>
                                    <li class="menu-spacing">
                                        <a href="/mission">Mission</a>
                                    </li>
                                    <li class="menu-spacing" style="margin-right: 3.5rem">
                                        <a href="/destinations">Destinations</a>
                                    </li>
                                    <li class="menu-spacing">
                                        <a href="/blog">Blog</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="search-bar ml-auto mr-auto" style="display: flex;" v-if="showSearch">
                            <SearchBar style="width: 100%;"></SearchBar>
                            <a href="/country" class="theme-btn theme-btn-small search-icon-btn"><i class="la la-search search-icon"></i></a>
                        </div>

                        <div class="main-menu-content mr-0 ml-4" v-if="showSearch">
                            <nav>
                                <ul>
                                    <li class="menu-spacing-2">
                                        <a href="/mission">Mission</a>
                                    </li>
                                    <li class="menu-spacing-2">
                                        <a href="/destinations">Destinations</a>
                                    </li>
                                    <li class="menu-spacing-2">
                                        <a href="/blog">Blog</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="nav-btn" v-if="!signedIn">
                            <a href="#" class="theme-btn theme-btn-transparent mr-1" data-toggle="modal" data-target="#signupPopupForm">Sign Up</a>
                            <a href="/profile" class="theme-btn">Log In</a>
                        </div>

                        <div class="dropdown dropdown-contain" v-else>
                            <a class="dropdown-toggle dropdown-btn dropdown-user--btn" href="#" role="button" data-toggle="dropdown">
                                <div class="d-flex align-items-center">
                                    <div class="avatar avatar-sm flex-shrink-0 mr-2"><img src="@/assets/trizen/team7.jpg" alt="team-img"></div>
                                    <span class="font-size-16 ml-1 mr-1 mt-1 font-weight-bold">Samuel Jackson</span>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-wrap mt-1 ml-2" style="padding: 0; min-width: 200px;">
                                <div class="dropdown-item list-group drop-reveal-list user-drop-reveal-list" style="padding: 0;">
                                    <a href="/profile" class="list-group-item list-group-item-action">
                                        <div class="msg-body">
                                            <div class="msg-content">
                                                <h3 class="title"><i class="la la-user mr-2"></i> View Profile</h3>
                                            </div>
                                        </div><!-- end msg-body -->
                                    </a>
                                    <a href="/wishlist" class="list-group-item list-group-item-action">
                                        <div class="msg-body">
                                            <div class="msg-content">
                                                <h3 class="title"><i class="la la-star-o mr-2"></i> My Wishlist</h3>
                                            </div>
                                        </div><!-- end msg-body -->
                                    </a>
                                    <a href="/settings" class="list-group-item list-group-item-action">
                                        <div class="msg-body">
                                            <div class="msg-content">
                                                <h3 class="title"><i class="la la-gear mr-2"></i> Settings</h3>
                                            </div>
                                        </div><!-- end msg-body -->
                                    </a>
                                    <div class="section-block"></div>
                                    <a href="/logout" class="list-group-item list-group-item-action">
                                        <div class="msg-body">
                                            <div class="msg-content">
                                                <h3 class="title"><i class="la la-power-off mr-2"></i> Logout</h3>
                                            </div>
                                        </div><!-- end msg-body -->
                                    </a>
                                </div><!-- end dropdown-item -->
                            </div><!-- end dropdown-menu -->
                        </div><!-- end dropdown -->
                    </div><!-- end menu-wrapper -->
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
        </div><!-- end container-fluid -->
    </div><!-- end header-menu-wrapper -->

    <!-- end modal-shared -->
    <div class="modal-popup">
        <div class="modal fade" id="signupPopupForm" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title title" id="exampleModalLongTitle">Sign Up</h5>
                            <p class="font-size-14">Hello! Welcome Create a New Account</p>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="la la-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="contact-form-action">
                            <form method="post">
                                <div class="input-box">
                                    <label class="label-text">Username</label>
                                    <div class="form-group">
                                        <span class="la la-user form-icon"></span>
                                        <input class="form-control" type="text" name="text" placeholder="Type your username">
                                    </div>
                                </div><!-- end input-box -->
                                <div class="input-box">
                                    <label class="label-text">Email Address</label>
                                    <div class="form-group">
                                        <span class="la la-envelope form-icon"></span>
                                        <input class="form-control" type="text" name="text" placeholder="Type your email">
                                    </div>
                                </div><!-- end input-box -->
                                <div class="input-box">
                                    <label class="label-text">Password</label>
                                    <div class="form-group">
                                        <span class="la la-lock form-icon"></span>
                                        <input class="form-control" type="text" name="text" placeholder="Type password">
                                    </div>
                                </div><!-- end input-box -->
                                <div class="input-box">
                                    <label class="label-text">Repeat Password</label>
                                    <div class="form-group">
                                        <span class="la la-lock form-icon"></span>
                                        <input class="form-control" type="text" name="text" placeholder="Type again password">
                                    </div>
                                </div><!-- end input-box -->
                                <div class="btn-box pt-3 pb-4">
                                    <button type="button" class="theme-btn w-100">Register Account</button>
                                </div>
                                <div class="action-box text-center">
                                    <p class="font-size-14">Or Sign up Using</p>
                                    <ul class="social-profile py-3">
                                        <li><a href="#" class="bg-5 text-white"><i class="lab la-facebook-f"></i></a></li>
                                        <li><a href="#" class="bg-6 text-white"><i class="lab la-twitter"></i></a></li>
                                        <li><a href="#" class="bg-7 text-white"><i class="lab la-instagram"></i></a></li>
                                        <li><a href="#" class="bg-5 text-white"><i class="lab la-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </form>
                        </div><!-- end contact-form-action -->
                    </div>
                </div>
            </div>
        </div>
    </div><!-- end modal-popup -->

    <!-- end modal-shared -->
    <div class="modal-popup">
        <div class="modal fade" id="loginPopupForm" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title title" id="exampleModalLongTitle2">Login</h5>
                            <p class="font-size-14">Hello! Welcome to your account</p>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="la la-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="contact-form-action">
                            <form method="post">
                                <div class="input-box">
                                    <label class="label-text">Username</label>
                                    <div class="form-group">
                                        <span class="la la-user form-icon"></span>
                                        <input class="form-control" type="text" name="text" placeholder="Type your username">
                                    </div>
                                </div><!-- end input-box -->
                                <div class="input-box">
                                    <label class="label-text">Password</label>
                                    <div class="form-group mb-2">
                                        <span class="la la-lock form-icon"></span>
                                        <input class="form-control" type="text" name="text" placeholder="Type your password">
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="custom-checkbox mb-0">
                                            <input type="checkbox" id="rememberchb">
                                            <label for="rememberchb">Remember me</label>
                                        </div>
                                        <p class="forgot-password">
                                            <a href="recover.html">Forgot Password?</a>
                                        </p>
                                    </div>
                                </div><!-- end input-box -->
                                <div class="btn-box pt-3 pb-4">
                                    <button type="button" class="theme-btn w-100">Login Account</button>
                                </div>
                                <div class="action-box text-center">
                                    <p class="font-size-14">Or Login Using</p>
                                    <ul class="social-profile py-3">
                                        <li><a href="#" class="bg-5 text-white"><i class="lab la-facebook-f"></i></a></li>
                                        <li><a href="#" class="bg-6 text-white"><i class="lab la-twitter"></i></a></li>
                                        <li><a href="#" class="bg-7 text-white"><i class="lab la-instagram"></i></a></li>
                                        <li><a href="#" class="bg-5 text-white"><i class="lab la-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </form>
                        </div><!-- end contact-form-action -->
                    </div>
                </div>
            </div>
        </div>
    </div><!-- end modal-popup -->

    <!-- end modal-shared -->
    <div class="modal-popup">
        <div class="modal fade" id="forgotPopupForm" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title title" id="exampleModalLongTitle3">Reset Password</h5>
                            <p class="font-size-14 pt-2">Enter your account email, and we will send you a link to to reset your password.
                                If you do not receive an email or encounter any issues, <a href="contact.html">contact us</a>.
                            </p>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="min-width: 32px !important;">
                            <span aria-hidden="true" class="la la-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="contact-form-action">
                            <form method="post">
                                <div class="input-box">
                                    <label class="label-text">Your Email</label>
                                    <div class="form-group">
                                        <span class="la la-envelope-o form-icon"></span>
                                        <input class="form-control" type="email" name="email" placeholder="Enter email address">
                                    </div>
                                </div>
                                <div class="btn-box">
                                    <button type="button" class="theme-btn">Reset Password</button>
                                </div>
                            </form>
                        </div><!-- end contact-form-action -->
                    </div>
                </div>
            </div>
        </div>
    </div><!-- end modal-popup -->
</template>

<script>
    import SearchBar from "@/components/SearchBar"

    export default {
        name: "Navbar",
        components: {
            SearchBar
        },

        computed: {
            showSearch() {
                return this.$route.name !== "Home"
            },

            signedIn(){
                return this.$route.name === "Profile" || this.$route.name === "Settings";
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menu-spacing {
        margin-right: 4rem;
        font-weight: 500;
    }

    .menu-spacing-2 {
        margin-right: 1rem;

        @media screen and (max-width: 1100px){
            margin: 0;
        }
    }

    .search-bar {
        min-width: 400px;

        @media (min-width: 1400px){
            min-width: 500px;
        }

        @media (min-width: 600px) and (max-width: 991px) {
            margin-top: -4.5rem;
        }

        @media (min-width: 380px) and (max-width: 750px){
            min-width: 280px;
        }

        @media (max-width: 599px){
            min-width: 95%;
            margin-right: 2em !important;
        }
    }

    .search-icon {
        transform: scale(1.4);
        margin-top: 0.9em;
    }

    .search-icon-btn {
        margin: auto;
        margin-left: 0.5em;
        height: 44px;
    }
</style>
