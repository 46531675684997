<template>
    <!-- ================================
    START BREADCRUMB AREA
================================= -->
    <section class="breadcrumb-area bread-bg">
        <div class="breadcrumb-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-content">
                            <div class="section-heading">
                                <h2 class="sec__title line-height-55">Travel Smarter with
                                    <br> TrekWisely.</h2>
                                <p class="sec__desc pt-3">We built an identity-based review agency and curated a community
                                    <br>of travelers so you can gain insight on where you want to travel next, no matter
                                    <br>who you are or what you look like.
                                </p>
                            </div>
                            <div class="btn-box pt-4">
                                <a href="#" data-toggle="modal" data-target="#signupPopupForm" class="theme-btn theme-btn-transparent border-0">Register Now</a>
                            </div>
                        </div><!-- end breadcrumb-content -->
                    </div><!-- end col-lg-12 -->
                </div><!-- end row -->
            </div><!-- end container -->
        </div><!-- end breadcrumb-wrap -->
    </section><!-- end breadcrumb-area -->
    <!-- ================================
        END BREADCRUMB AREA
    ================================= -->

    <!-- ================================
    START ABOUT AREA
================================= -->
    <section class="about-area padding-top-70px padding-bottom-30px overflow-hidden">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="section-heading margin-bottom-40px">
                        <h2 class="sec__title">Our Mission</h2>
                        <h4 class="title font-size-16 line-height-26 pt-4 pb-2">TrekWisely is on a mission to make travel better, safer, and more enjoyable for everyone.</h4>
                        <p class="sec__desc font-size-16 pb-3">We believe that travel leads to exposure and exposure will inevitably lead to a more unified society. One where we cherish our differences and revel in our similarities. Here at TrekWisely, we want two things:</p>
                        <p class="sec__desc font-size-16 "><b>1)</b> For us to see the world, and</p>
                        <p class="sec__desc font-size-16 pb-3"><b>2)</b> For the world to see us.</p>
                        <p class="sec__desc font-size-16 pb-3">Who is this elusive “us?” We come in all shades. Some of us keep it halal, while others keep it kosher. There are those of us who are trans, gay, bi and queer, while others are fierce allies. We’re solo travelers, first time travelers, travelers looking to splurge, and travelers on a budget.</p>
                        <p class="sec__desc font-size-16">Whoever you are, however, you identify, you should feel safe, welcome, and encouraged to travel. By increasing your access to tips, tricks, and reviews from people similar to you, it is our sincere hope that you’ll take that trip, meet new people, and explore the world.</p>
                    </div><!-- end section-heading -->
                </div><!-- end col-lg-6 -->
                <div class="col-lg-5 ml-auto">
                    <div class="image-box">
                        <img src="@/assets/img/11.jpg" alt="about-img" class="img__item img__item-1">
                    </div>
                </div><!-- end col-lg-5 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end about-area -->
    <!-- ================================
        END ABOUT AREA
    ================================= -->


    <!-- ================================
        START VIDEO AREA
    ================================= -->
    <section class="video-area text-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="video-content">
                        <div class="video-content-img ripple-bg">
                            <img src="@/assets/img/12.jpg" alt="">
                        </div>
                        <div class="video-content-box">
                            <div class="section-heading">
                                <h2 class="sec__title text-white line-height-55">Share Your Experience <br> with the World</h2>
                            </div><!-- end section-heading -->
                            <div class="btn-box pt-4">
                                <a href="#" class="icon-element text-white" data-fancybox="video" data-src="https://www.youtube.com/watch?v=0GZSfBuhf6Y"
                                   data-speed="700">
                                    <i class="la la-play"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end video-area -->
    <!-- ================================
        END VIDEO AREA
    ================================= -->

    <!-- ================================
        START INFO AREA
    ================================= -->
    <section class="info-area section-bg padding-top-200px padding-bottom-80px text-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-heading text-center">
                        <h2 class="sec__title">How does TrekWisely work?</h2>
                    </div><!-- end section-heading -->
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
            <div class="row padding-top-70px">
                <div class="col-lg-4 responsive-column">
                    <div class="icon-box icon-layout-3 d-flex">
                        <div class="info-icon flex-shrink-0">
                            <i class="la la-user-plus ml-1"></i>
                        </div><!-- end info-icon-->
                        <div class="info-content">
                            <span class="info__num">1</span>

                            <h4 class="info__title">Sign Up</h4>
                            <p class="info__desc">
                                Create an account with TrekWisely, and fill out your travel profile.
                            </p>

                        </div><!-- end info-content -->
                    </div><!-- end icon-box -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="icon-box icon-layout-3 d-flex">
                        <div class="info-icon flex-shrink-0">
                            <i class="la la-search-location" style="transform: scale(1.05)"></i>
                        </div><!-- end info-icon-->
                        <div class="info-content">
                            <h4 class="info__title">Search Destinations</h4>
                            <p class="info__desc">
                                Pick your ideal travel spot with our smart filters that let you optimize for race, gender, religion, and more!
                            </p>
                            <span class="info__num">2</span>
                        </div><!-- end info-content -->
                    </div><!-- end icon-box -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="icon-box icon-layout-3 d-flex">
                        <div class="info-icon flex-shrink-0">
                            <i class="la la-edit ml-1"></i>
                        </div><!-- end info-icon-->
                        <div class="info-content">
                            <h4 class="info__title">Review Your Trip</h4>
                            <p class="info__desc">
                                Rate and post your travel experience so other travelers can learn from you.
                            </p>
                            <span class="info__num">3</span>
                        </div><!-- end info-content -->
                    </div><!-- end icon-box -->
                </div><!-- end col-lg-4 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </section><!-- end info-area -->
    <!-- ================================
        END INFO AREA
    ================================= -->

    <!-- ================================
        START FAQ AREA
    ================================= -->
    <section class="faq-area section--padding padding-bottom-60px">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-heading text-center">
                        <h2 class="sec__title">Frequently Asked Questions</h2>
                    </div><!-- end section-heading -->
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
            <div class="row padding-top-40px">
                <div class="col-lg-6">
                    <div class="accordion accordion-item" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="faqHeadingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-link d-flex align-items-center justify-content-between" type="button" data-toggle="collapse" data-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                                        <span>Why do I need an account?</span>
                                        <i class="la la-minus"></i>
                                        <i class="la la-plus"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="faqCollapseOne" class="collapse show" aria-labelledby="faqHeadingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority
                                        have suffered alteration in some form, by injected humour, or randomised words
                                        which don't look even slightly believable.</p>
                                </div>
                            </div>
                        </div><!-- end card -->
                        <div class="card">
                            <div class="card-header" id="faqHeadingTwo">
                                <h2 class="mb-0">
                                    <button class="btn btn-link d-flex align-items-center justify-content-between" type="button" data-toggle="collapse" data-target="#faqCollapseTwo" aria-expanded="false" aria-controls="faqCollapseTwo">
                                        <span>How do I rate a country and leave a review?</span>
                                        <i class="la la-minus"></i>
                                        <i class="la la-plus"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="faqCollapseTwo" class="collapse" aria-labelledby="faqHeadingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority
                                        have suffered alteration in some form, by injected humour, or randomised words
                                        which don't look even slightly believable.</p>
                                </div>
                            </div>
                        </div><!-- end card -->
                        <div class="card">
                            <div class="card-header" id="faqHeadingThree">
                                <h2 class="mb-0">
                                    <button class="btn btn-link d-flex align-items-center justify-content-between" type="button" data-toggle="collapse" data-target="#faqCollapseThree" aria-expanded="false" aria-controls="faqCollapseThree">
                                        <span>I'm a country representative. How do I respond to reviews?</span>
                                        <i class="la la-minus"></i>
                                        <i class="la la-plus"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="faqCollapseThree" class="collapse" aria-labelledby="faqHeadingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority
                                        have suffered alteration in some form, by injected humour, or randomised words
                                        which don't look even slightly believable.</p>
                                </div>
                            </div>
                        </div><!-- end card -->
                    </div>
                </div><!-- end col-lg-7 -->
                <div class="col-lg-6" id="accordionExample2">
                    <div class="accordion accordion-item">
                        <div class="card">
                            <div class="card-header" id="faqHeadingFour">
                                <h2 class="mb-0">
                                    <button class="btn btn-link d-flex align-items-center justify-content-between" type="button" data-toggle="collapse" data-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                                        <span>How does the search and filtering system work?</span>
                                        <i class="la la-minus"></i>
                                        <i class="la la-plus"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="faqCollapseFour" class="collapse show" aria-labelledby="faqHeadingFour" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority
                                        have suffered alteration in some form, by injected humour, or randomised words
                                        which don't look even slightly believable.</p>
                                </div>
                            </div>
                        </div><!-- end card -->
                        <div class="card">
                            <div class="card-header" id="faqHeadingFive">
                                <h2 class="mb-0">
                                    <button class="btn btn-link d-flex align-items-center justify-content-between" type="button" data-toggle="collapse" data-target="#faqCollapseFive" aria-expanded="false" aria-controls="faqCollapseFive">
                                        <span>Can I make my review anonymous?</span>
                                        <i class="la la-minus"></i>
                                        <i class="la la-plus"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="faqCollapseFive" class="collapse" aria-labelledby="faqHeadingFive" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority
                                        have suffered alteration in some form, by injected humour, or randomised words
                                        which don't look even slightly believable.</p>
                                </div>
                            </div>
                        </div><!-- end card -->
                        <div class="card">
                            <div class="card-header" id="faqHeadingSix">
                                <h2 class="mb-0">
                                    <button class="btn btn-link d-flex align-items-center justify-content-between" type="button" data-toggle="collapse" data-target="#faqCollapseSix" aria-expanded="false" aria-controls="faqCollapseSix">
                                        <span>I'd like to work here. Where can I apply?</span>
                                        <i class="la la-minus"></i>
                                        <i class="la la-plus"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="faqCollapseSix" class="collapse" aria-labelledby="faqHeadingSix" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority
                                        have suffered alteration in some form, by injected humour, or randomised words
                                        which don't look even slightly believable.</p>
                                </div>
                            </div>
                        </div><!-- end card -->
                    </div>
                </div><!-- end col-lg-4 -->
            </div><!-- end row -->
            <div class="accordion-help-text pt-2">
                <p class="font-size-14 font-weight-regular">Any questions? Just visit our <a href="#" class="color-text">Help center</a> or <a href="#" class="color-text">Contact Us</a></p>
            </div>
        </div><!-- end container -->
    </section><!-- end faq-area -->
    <!-- ================================
        END FAQ AREA
    ================================= -->

    <div class="section-block"></div>

    <!-- ================================
        START INFO AREA
    ================================= -->
    <section class="info-area padding-top-60px padding-bottom-40px text-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-heading">
                        <h2 class="sec__title">Our Team</h2>
                    </div><!-- end section-heading -->
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
            <div class="row padding-top-100px">
                <div class="col-lg-4 responsive-column">
                    <div class="card-item team-card">
                        <div class="card-img">
                            <img src="@/assets/img/nicole.jpg" alt="team-img">
                        </div>
                        <div class="card-body">
                            <h3 class="card-title">Nicole Wardlaw</h3>
                            <p class="card-meta">Chief Executive Officer</p>
                            <p class="card-text font-size-15 pt-2">Ligula vehicula enenatis semper, magna lorem aliquet lacusin ante dapibus dictum fugats vitaes nemo minima.</p>
                            <ul class="social-profile padding-top-20px pb-2">
                                <li><a href="#"><i class="lab la-facebook-f"></i></a></li>
                                <li><a href="#"><i class="lab la-twitter"></i></a></li>
                                <li><a href="#"><i class="lab la-instagram"></i></a></li>
                                <li><a href="#"><i class="lab la-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="card-item team-card">
                        <div class="card-img">
                            <img src="@/assets/img/niya.jpg" alt="team-img">
                        </div>
                        <div class="card-body">
                            <h3 class="card-title">Niya Wardlaw</h3>
                            <p class="card-meta">Chief Operating Officer</p>
                            <p class="card-text font-size-15 pt-2">Ligula vehicula enenatis semper, magna lorem aliquet lacusin ante dapibus dictum fugats vitaes nemo minima.</p>
                            <ul class="social-profile padding-top-20px pb-2">
                                <li><a href="#"><i class="lab la-facebook-f"></i></a></li>
                                <li><a href="#"><i class="lab la-twitter"></i></a></li>
                                <li><a href="#"><i class="lab la-instagram"></i></a></li>
                                <li><a href="#"><i class="lab la-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
                <div class="col-lg-4 responsive-column">
                    <div class="card-item team-card">
                        <div class="card-img">
                            <img src="@/assets/img/roman.jpg" alt="team-img">
                        </div>
                        <div class="card-body">
                            <h3 class="card-title">Roman Scott</h3>
                            <p class="card-meta">Chief Technology Officer</p>
                            <p class="card-text font-size-15 pt-2">Ligula vehicula enenatis semper, magna lorem aliquet lacusin ante dapibus dictum fugats vitaes nemo minima.</p>
                            <ul class="social-profile padding-top-20px pb-2">
                                <li><a href="#"><i class="lab la-facebook-f"></i></a></li>
                                <li><a href="#"><i class="lab la-twitter"></i></a></li>
                                <li><a href="#"><i class="lab la-instagram"></i></a></li>
                                <li><a href="#"><i class="lab la-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div><!-- end card-item -->
                </div><!-- end col-lg-4 -->
            </div><!-- end row -->

            <a href="#" class="theme-btn">Join Our Team</a>
        </div><!-- end container -->
    </section><!-- end info-area -->
    <!-- ================================
        END INFO AREA
    ================================= -->
</template>

<script>
    export default {
        name: "Mission"
    }
</script>

<style scoped>

</style>
